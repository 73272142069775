import React from 'react';
import AlertComponent from '../common/Alert';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import MenuDrawer from '../common/Drawer/MenuDrawer';
import packageJson from '../../../package.json';

const AboutUsPage: React.FC = () => {
  const personA = {
    name: 'Maxime Hazebroucq',
    phone: '+33 7 88 41 19 99',
    email: 'maxime@emax.pro',
  };

  const personB = {
    name: 'Julien Hazebroucq',
    phone: '',
    email: 'julien@emax.pro',
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <MenuDrawer />
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <AlertComponent />
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box textAlign="center"  my={4}>
                <Typography variant="h2" gutterBottom>
                Powered by EMAX.PRO
                </Typography>
              </Box>
              <Divider />
              <Box textAlign="center"  my={4}>
                  <Typography variant="h4" gutterBottom>
                  {personA.name}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                  Téléphone : {personA.phone}
                  </Typography>
                  <Typography variant="body1">
                  Email : {personA.email}
                  </Typography>
              </Box>
              <Divider />
              <Box textAlign="center"  my={4}>
                  <Typography variant="h4" gutterBottom>
                  {personB.name}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                  Téléphone : {personB.phone}
                  </Typography>
                  <Typography variant="body1">
                  Email : {personB.email}
                  </Typography>
              </Box>
              <Divider />
              <Box textAlign="center"  my={4}>
                <Typography variant="body2" color="textSecondary" align="center">
                    Version: {packageJson.version}
                </Typography>
              </Box>

            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUsPage;
