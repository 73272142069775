import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import sessionStorage from 'redux-persist/lib/storage/session'; // for sessionStorage
import authReducer from './authReducer';
import custReducer from './custReducer';
import alertReducer from './alertReducer';
import usersReducer from './usersReducer';
import servicesReducer from './servicesReducer';
import quoteReducer from './quoteReducer';
import calculReducer from './calculReducer';
import notificationReducer from './notificationReducer';
import chartsReducer from './chartsReducer';
import logsReducer from './logsReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cust', 'quotes', 'notifications', 'services'],
};

const userPersistConfig = {
  key: 'auth',
  storage: sessionStorage,
}

const rootReducer = combineReducers({
  auth: persistReducer(userPersistConfig, authReducer),
  users: usersReducer,
  cust: custReducer,
  alert: alertReducer,
  quotes: quoteReducer,
  services: servicesReducer,
  calcul: calculReducer,
  notifications: notificationReducer,
  charts: chartsReducer,
  logs: logsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
