import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { resulsCalculType } from '../types/services/serviceParameters';

interface CalculState {
  result: resulsCalculType | null;
  loading: boolean;
  error: string | null;
}

const initialState: CalculState = {
  result: null,
  loading: false,
  error: null,
};

const calculSlice = createSlice({
  name: 'calcul',
  initialState,
  reducers: {
    clearCalculationResult(state) {
      state.loading = true;
      state.result = null;
      state.error = null;
    },

    calculationRowRequest(state) {
      state.loading = true;
      state.error = null;
    },
    calculationRowSuccess(state, action: PayloadAction<resulsCalculType>) {
      state.loading = false;
      state.result = action.payload;
      state.error = null;
    },
    calculationRowFailure(state) {
      state.loading = false;
      if (state.result) {
        state.result.isValid = false;
      }
      state.error = null;
    },
    calculationRequest(state) {
      state.loading = true;
      state.error = null;
    },
    calculationSuccess(state, action: PayloadAction<resulsCalculType>) {
      state.loading = false;
      state.result = action.payload;
      state.error = null;
    },
    calculationFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.result = null;
      state.error = action.payload;
    },
  },
});

export const {
  calculationRowRequest,
  calculationRowSuccess,
  calculationRowFailure,
  calculationRequest,
  calculationSuccess,
  calculationFailure,
  clearCalculationResult
} = calculSlice.actions;

export const selectCalculLoading = (state: RootState) => state.calcul.loading;

export default calculSlice.reducer;
