import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/api';
import { AuthResponse } from '../types/types';
import { persistor } from '../store/store';
import { logoutRequest, logoutSuccess, logoutFailure } from '../reducers/authReducer';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';
import { UserType } from '../types/users';

export const login = createAsyncThunk<AuthResponse, {email: string; password: string}, { rejectValue: string }>(
  'auth/login',
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/v1/auth/login', { email, password });
      return response.data;
    } catch (error :any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    try {
      dispatch(logoutRequest());
      await axiosInstance.post('/v1/auth/logout');
      await persistor.purge();
      dispatch(logoutSuccess());
      return true;
    } catch (error: any) {
      dispatch(logoutFailure(error));
    }
  }
);

export const registerUser = createAsyncThunk<UserType, { firstname: string; lastname: string; email: string; password: string }, { rejectValue: string }>(
  'auth/registerUser',
  async ({ firstname, lastname, email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/v1/auth/signin', { firstname, lastname, email, password });
      dispatch(setAlertWithDuration("Inscription réussie.", "success", "200"));
      return response.data.user as UserType;
    } catch (error:any) {
      if (error.response) {
        const errorMessage = error.response.data.message || error.message;
        const errorCode = error.response.status;
        dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
        return rejectWithValue(error.response.data);
      } else {
        dispatch(setAlertWithDuration(error.message, 'error', '500'));
        return rejectWithValue('Une erreur inattendue s\'est produite');
      }
    }
  }
);


export const resetPassword = createAsyncThunk<void, { email: string }, { rejectValue: string }>(
  'auth/resetPassword',
  async ({ email }, { dispatch }) => {
    try {
      await axiosInstance.post('/v1/auth/resetPassword', { email });
      dispatch(setAlertWithDuration("Email de réinitialisation de mot de passe envoyé.", "info", "200"));
    } catch (error:any) {
      dispatch(setAlertWithDuration("Email de réinitialisation de mot de passe envoyé.", "info", "200"));
    }
  }
);

export const updatePassword = createAsyncThunk<{ message: string }, { token: string, password: string }, { rejectValue: string }>(
  'auth/updatePassword',
  async ({ token, password }, { dispatch, rejectWithValue }) => {
    try {
      await axiosInstance.post('/v1/auth/updatePassword', {token, password});
      dispatch(setAlertWithDuration("Mot de passe mis a jour.", "info", "200"));
      return { message: 'Password updated' };
    } catch( error :any) {
      dispatch(setAlertWithDuration(error.message, "error", "500"));
      return rejectWithValue(error.response.data);
    }
  }
); 

export const updateProfile = createAsyncThunk<UserType, UserType, { rejectValue: string }>(
  'auth/updateProfile',
  async (user, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/v1/users/${user.id}`, user);
      dispatch(setAlertWithDuration('Mise a jour effectuée', 'success', '200'));
      return response.data;
    } catch (error:any) {
      if (!error.response) {
        throw error;
      }
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      return rejectWithValue(error.response.data);
    }
  }
);


export const activateAccount = createAsyncThunk<{ result: boolean }, { token: string }, { rejectValue: string }>(
  'auth/activateAccount',
  async ({ token }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/v1/auth/activateAccount', { token });
      dispatch(setAlertWithDuration(response.data.message, 'success', '200'));
      return response.data;
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      return rejectWithValue(errorMessage);
    }
  }
);