import { AdresseType } from '../redux/types/customers';
import { AutreParametersType, DecoupeRainageParametersType, DorureParametersType, DosCarreColleParametersType, EmballageParametersType, PelliculageParametersType, PiqureDoubleParametersType, PiqureSimpleParametersType, PliageParametersType, ServiceParametersType, VernisParametersType } from '../redux/types/services/serviceParameters';


export const formatAddress = (address: AdresseType) => {
    if (!address) {
      return '';
    }
    
    const { row1, row2, row3, city, zipCode, country } = address;
  
    let formattedAddress = '';
  
    // Ajoutez chaque partie de l'adresse à la chaîne formatée si elle n'est pas nulle
    if (row1) formattedAddress += row1;
    if (row2) formattedAddress += (formattedAddress ? ` ${row2}` : row2);
    if (row3) formattedAddress += (formattedAddress ? ` ${row3}` : row3);
    if (zipCode && city) {
      formattedAddress += (formattedAddress ? `, ${zipCode} ${city}` : `${zipCode} ${city}`);
    } else {
      if (zipCode) formattedAddress += (formattedAddress ? `, ${zipCode}` : zipCode);
      if (city) formattedAddress += (formattedAddress ? `, ${city}` : city);
    }
    if (country) formattedAddress += (formattedAddress ? `, ${country}` : country);
  
    return formattedAddress;
};

export const getServiceType = (selectedService: string): ServiceParametersType | null => {
  const serviceLowerCase = selectedService.toLowerCase();

  switch (serviceLowerCase) {
    case 'pelliculage':
      return {} as PelliculageParametersType;
    case 'vernis':
      return {} as VernisParametersType;
    case 'decoupeRainage':
      return {} as DecoupeRainageParametersType;
    case 'autre':
      return {} as AutreParametersType;
    case 'emballage':
      return {} as EmballageParametersType;
    case 'piqureSimple':
      return {} as PiqureSimpleParametersType;
    case 'piqureDouble':
      return {} as PiqureDoubleParametersType;
    case 'dosCarreColle':
      return {} as DosCarreColleParametersType;
    case 'dorure':
      return {} as DorureParametersType;
    case 'pliage':
      return {} as PliageParametersType;
    default:
      return null;
  }
};