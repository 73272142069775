import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { deleteNotification } from '../../redux/actions/notificationsActions';
import { Card, CardContent, CardActions, Typography, Button } from '@mui/material';
import { CardTitle } from 'react-bootstrap';

const NotificationsComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const notifications = useSelector((state: RootState) => state.notifications.notifications);
    const loading = useSelector((state: RootState) => state.notifications.loading);
    const error = useSelector((state: RootState) => state.notifications.error);

    const handleDelete = (notificationId: number) => {
        dispatch(deleteNotification(notificationId));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
            {notifications.length === 0 ? (
                <Typography variant="body2" style={{ textAlign: 'center', marginTop: '5px' }}>
                    Aucune notification pour le moment.
                </Typography>
            ) : (
                notifications.map(notification => (
                    <Card key={notification.id} style={{ marginTop: '5px' }}>
                        <CardContent>
                            <CardTitle>
                                <Typography gutterBottom variant="h5" component="div">
                                    {notification.title}
                                </Typography>
                            </CardTitle>
                            <Typography variant="body2">
                                {notification.message}
                            </Typography>
                        </CardContent>
                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            <Button
                                onClick={() => handleDelete(notification.id)}
                            >
                                Fermer
                            </Button>
                        </CardActions>
                    </Card>
                ))
            )}
        </div>
    );
};

export default NotificationsComponent;
