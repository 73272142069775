import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/api';
import { PasswordType, UserType } from '../types/users';
import { fetchUsersRequest, fetchUsersSuccess, fetchUsersFailure } from '../reducers/usersReducer';
import { changePasswordRequest, changePasswordSuccess, changePasswordFailure } from '../reducers/usersReducer';
import { addUserRequest, addUserSuccess, addUserFailure } from '../reducers/usersReducer';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';

export const changePassword = createAsyncThunk(
  'users/changePassword',
  async (password: PasswordType, { dispatch }) => {
    try {
          dispatch(changePasswordRequest());
          await axiosInstance.post('/v1/users/change-password', password);
          dispatch(changePasswordSuccess());
          dispatch(setAlertWithDuration('Mise a jour effectuée', 'success', '200'));
      } catch (error :any) {
        const errorMessage = error.response?.data?.message || error.message;
        const errorCode = error.response?.status;
        dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
        dispatch(changePasswordFailure(error));
      }
  }
);

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (_, { dispatch }) => {
      try {
          dispatch(fetchUsersRequest());
          const response = await axiosInstance.get('/v1/users');
          const data: UserType[] = response.data;
          dispatch(fetchUsersSuccess(data));
      } catch (error :any) {
          const errorMessage = error.response?.data?.message || error.message;
          const errorCode = error.response?.status;
          dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
          dispatch(fetchUsersFailure(errorMessage));
      }
  }
);


export const addUser = createAsyncThunk(
  'users/addUser',
  async (user: UserType, { dispatch }) => {
    try {
      dispatch(addUserRequest());
      const response = await axiosInstance.post('/v1/users', user);
      const data: UserType = response.data;
      dispatch(addUserSuccess(data));
      return { ...user, id: response.data.id };
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(addUserFailure(errorMessage));
    }
  }
);


export const removeUsers = createAsyncThunk(
  'users/removeUsers',
  async (Users: UserType[], { dispatch }) => {
    try {
      const deletedUserIds: number[] = [];
      for (const user of Users) {
        await axiosInstance.delete(`/v1/users/${user.id}`);
        deletedUserIds.push(user.id);
      }
      return deletedUserIds;
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
    }
  }
);


export const updateUser = createAsyncThunk<UserType, UserType, { rejectValue: string }>(
  'users/updateUser',
  async (user: UserType, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/v1/users/${user.id}`, user);
      dispatch(setAlertWithDuration('Mise a jour effectuée', 'success', '200'));
      return response.data;
    } catch (error:any) {
      if (!error.response) {
        throw error;
      }
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateUserStatus = createAsyncThunk(
  'users/updateUserStatus',
  async ({userId, newStatus} : {userId: number, newStatus: boolean} , { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/v1/users/${userId}/status`, { enabled: newStatus });
      return response.data;
    } catch (error:any) {
      if (!error.response) {
        throw error;
      }
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode)); // Dispatchez l'action pour définir l'alerte d'erreur
      return rejectWithValue(error.response.data); // Retournez les données d'erreur au reducer
    }
  }
);