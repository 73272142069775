import React, { useEffect } from 'react';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { useSelector } from 'react-redux';
import { Box, Card, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { fetchUsers } from '../../redux/actions/usersActions'; 
import { fetchLogs } from '../../redux/actions/logsActions';

const LogsPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const users = useSelector((state: RootState) => state.users.users);
    const logs = useSelector((state: RootState) => state.logs.result);

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchLogs());
    }, [dispatch]);

    const filteredUsers = users.filter(user => user.lastLogin);


    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1, padding: '20px' }}>
                <Card sx={{ minHeight:'450px' }}>
                    <Box>
                    <Typography variant="h4" sx={{ p: 2 }} gutterBottom>
                        Dernieres connexions
                    </Typography>
                    <TableContainer sx={{ p:2, width: '95%' }}>
                        <Table >
                            <TableBody>
                            <TableRow>
                                <TableCell align="left">Prénom</TableCell>
                                <TableCell align="left">Nom</TableCell>
                                <TableCell align="right">Dernière connexion</TableCell>
                            </TableRow>

                            {filteredUsers.map((row) => (
                                <TableRow key={row.id}  style={{ cursor: 'default' }}>
                                <TableCell align="left">{row.firstName}</TableCell>
                                <TableCell align="left">{row.lastName}</TableCell>
                                <TableCell align="right">{row?.lastLogin ? new Date(row.lastLogin).toLocaleDateString(): ''}</TableCell>

                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        
                    </Box>
                </Card>

                <Divider sx={{m:2}} />

                <Card sx={{ minHeight:'450px' }} >
                    <Box>
                    <Typography variant="h4" sx={{ p: 2 }} gutterBottom>
                        Dernieres actions
                    </Typography>
                    <TableContainer sx={{ p:2, width: '95%' }}>
                        <Table >
                            <TableBody>
                            <TableRow>
                                <TableCell align="left">Utilisateur</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>

                            {logs.map((row) => (
                                <TableRow key={row.email}  style={{ cursor: 'default' }}>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{new Date(row.timestamp).toLocaleDateString()}</TableCell>
                                <TableCell align="right">{row.action}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};

export default LogsPage;
