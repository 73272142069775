import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from '../redux/store/store';
import { isTokenValid } from '../utils/authUtils';

interface Props {
  element: React.ComponentType;
}

const PrivateRoute: React.FC<Props> = ({ element: Component, ...rest }) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const location = useLocation();
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (accessToken) {
      try {
        setIsValid(isTokenValid(accessToken));
      } catch (error) {
        console.error('Erreur lors de la vérification de la validité du token JWT :', error);
      }
    } else {
      setIsValid(false);
    }
  }, [accessToken]);

  return isAuthenticated && isValid ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
