import { createTheme } from '@mui/material/styles';
import backgroundImage from '../assets/backgound.svg';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d32f2f',
    },
    secondary: {
      main: '#e0dada',
    },
    error: {
      main: '#c62828',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: '#d32f2f',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: '#d32f2f',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#d32f2f',
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: '#d32f2f',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#D32F2F",
          color: '#e5e7eb',
          '&.sortable-cell': {
            cursor: 'pointer',
          }
        },
        data :{
          fontSize:'0.9em',
          height:'20px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff', 
          boxShadow: '0px 3px 6px rgba(0,0,0,0.16), 0px 3px 6px rgba(0,0,0,0.23)',
          padding: '16px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: '210px',
          flexShrink: 0,
        },
        paper: {
          width: '210px',
          backgroundColor: '#242930',
          color: '#e5e7eb',
          border: 'none',
          boxShadow: 'none',
        },
      },
    }
  },
});

document.body.style.backgroundImage = `url(${backgroundImage})`;
document.body.style.backgroundRepeat = 'no-repeat';
document.body.style.backgroundSize = 'cover';
document.body.style.backgroundPosition = 'center';
document.body.style.margin = '0';
document.body.style.padding = '0';
document.body.style.height = '100vh';

export default theme;
