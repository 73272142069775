import React from 'react';
import { Box, Typography, TextField, Button, Container } from '@mui/material';
import { useForm } from 'react-hook-form';
import AlertComponent from '../common/Alert';
import theme from '../../shared/theme/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import logo from '../../shared/assets/logo.svg';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../redux/store/store';
import { updatePassword } from '../../redux/actions/authActions';
import { useNavigate } from 'react-router-dom';

const resetPasswordSchema = yup.object().shape({
  token: yup.string(),
  password: yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Les mots de passe doivent correspondre').required('La confirmation du mot de passe est obligatoire'),
});

const ResetPasswordPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  // Utilisation de useLocation pour récupérer le token depuis l'URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token') || '';

  const onSubmit = (data: any) => {
    dispatch(updatePassword({token: token, password: data.password}))
    reset();
    navigate('/', { replace: true });
  };

  return (
    <Container component="main" maxWidth="xs">
      <AlertComponent />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
          boxShadow: 1,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <img src={logo} alt="Logo" />
        <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mt: 2 }}>
          Réinitialiser le mot de passe
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, width: '100%' }}>
          <input type="hidden" {...register('token')} value={token} />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="new-password"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password', { required: true })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Confirmation du mot de passe"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            {...register('confirmPassword', { required: true })}
          />
          <Button type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
            Réinitialiser le mot de passe
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
