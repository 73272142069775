import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import AlertComponent from '../common/Alert';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../redux/store/store';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../common/LoadingIndicator';
import { activateAccount } from '../../redux/actions/authActions';

const ActivateAccountPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token') || '';
  

  useEffect(() => {
    const activate = async () => {
      if (token) {
        await dispatch(activateAccount({ token }));
      }
      navigate('/', { replace: true });
    };

    activate();
  }, [dispatch, navigate, token]);

  return (
    <Container component="main" maxWidth="xs">
      <LoadingIndicator />
      <AlertComponent />
    </Container>
  );
};

export default ActivateAccountPage;
