import React from 'react';
import { TextField, Grid } from '@mui/material';
import { AdresseType } from '../../redux/types/customers';
import { Box } from '@mui/system';

interface AddressFormProps {
  address: AdresseType;
  onChange: (address: AdresseType) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({ address, onChange }) => {
  const handleChange = (field: keyof AdresseType, value: string) => {
    onChange({ ...address, [field]: value });
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '16px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Ligne 1"
            value={address.row1}
            onChange={(e) => handleChange('row1', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Ligne 2"
            value={address.row2}
            onChange={(e) => handleChange('row2', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Ligne 3"
            value={address.row3}
            onChange={(e) => handleChange('row3', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Ville"
            value={address.city}
            onChange={(e) => handleChange('city', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Code postal"
            value={address.zipCode}
            onChange={(e) => handleChange('zipCode', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Pays"
            value={address.country}
            onChange={(e) => handleChange('country', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressForm;
