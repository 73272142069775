import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Tooltip, Box } from '@mui/material';
import { RootState } from '../../redux/store/store';
import { ServiceType } from '../../redux/types/services';

interface ServicesMenuProps {
  onServiceSelect: (serviceId: number) => void;
}

const ServicesMenu: React.FC<ServicesMenuProps> = ({ onServiceSelect }) => {
  const services = useSelector((state: RootState) => state.services.services);

  return (    
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Grid container spacing={2} justifyContent="center">
        {services.map((service: ServiceType) => (
          <Grid item key={service.id}>
            <Tooltip title={service.description} arrow>
              <Button
                onClick={() => onServiceSelect(service.id)}
                variant="outlined"
                size="small"
                fullWidth
              >
                {service.name}
              </Button>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicesMenu;
