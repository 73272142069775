import React, { FC } from 'react';
import { Box, TextField, Button } from '@mui/material';
import CustomModal from '../common/modal/CustomModal';
import AlertComponent from '../common/Alert';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../redux/store/store';
import { registerUser } from '../../redux/actions/authActions';
import { useNavigate } from 'react-router-dom';

interface SignupModalProps {
  open: boolean;
  onClose: () => void;
}

const signupSchema = yup.object().shape({
  firstname: yup.string(),
  lastname: yup.string(),
  email: yup.string().email('Veuillez entrer une adresse email valide').required('L\'email est obligatoire'),
  password: yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Les mots de passe doivent correspondre').required('La confirmation du mot de passe est obligatoire'),
});

const SignupModal: FC<SignupModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(signupSchema),
  });
  
  const onSubmit = (data: any) => {
    const transformedData = {
      ...data,
      email: data.email.toLowerCase(),
    };
    dispatch(registerUser(transformedData));
    reset();
    navigate('/', { replace: true });
  };
  
  return (
    <CustomModal title="" open={open} onClose={onClose}>
      <AlertComponent />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
            label="Prénom"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.firstname}
            helperText={errors.firstname?.message}
            {...register('firstname', { required: true })}
        />
        <TextField
            label="Nom"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.lastname}
            helperText={errors.lastname?.message}
            {...register('lastname', { required: true })}
        />
        <TextField
            label="Adresse email"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register('email', { required: true })}
        />
        <TextField
            label="Mot de passe"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password', { required: true })}
        />
        <TextField
            label="Confirmation du mot de passe"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            {...register('confirmPassword', { required: true })}
        />
        {/* Centrer le bouton dans un conteneur Box avec une marge top */}
        <Box mt={2} textAlign="center">
            <Button type="submit" variant="contained" color="primary">
            Créer un compte
            </Button>
        </Box>
        </form>

    </CustomModal>
  );
};

export default SignupModal;
