import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Container, Typography, ListItemButton } from '@mui/material';
import MenuDrawer from '../common/Drawer/MenuDrawer';
import AlertComponent from '../common/Alert';
import LoadingIndicator from '../common/LoadingIndicator';
import ProfilePage from './ProfilePage';
import UsersPage from './UsersPage';
import LogsPage from './LogsPage';

const ParametersPage: React.FC = () => {

    const [selectedMenuItem, setSelectedMenuItem] = useState<string>('Profil');

    const handleMenuItemClick = (item: string) => {
        setSelectedMenuItem(item);
    };

    const renderContent = () => {
        switch (selectedMenuItem) {
            case 'Profil':
                return <ProfilePage />;
            case 'Users':
                return <UsersPage />;
            case 'Logs':
                return <LogsPage />
            default:
                return <div>Sélectionnez une option</div>;
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <MenuDrawer />
            <Box sx={{ flexGrow: 1, padding: '20px' }}>
                <Box sx={{ display: 'flex'}}>
                    <Box sx={{ width: 200, mt:5 }}>
                        <Typography sx={{ mt: 4, mb: 2, fontWeight:'bold' }} variant="body2" component="div">
                            PARAMETRES DE BASE
                        </Typography>
                        <List sx={{width: '100%'}}>
                            <ListItem disablePadding>
                                <ListItemButton selected={selectedMenuItem === 'Profil'} onClick={() => handleMenuItemClick('Profil')}>
                                    <ListItemText primary="Mon profil"  />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton selected={selectedMenuItem === 'Users'} onClick={() => handleMenuItemClick('Users')}>
                                    <ListItemText primary="Gestion des utilisateurs" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton selected={selectedMenuItem === 'Logs'} onClick={() => handleMenuItemClick('Logs')}>
                                    <ListItemText primary="Logs système" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                    <Box sx={{ flexGrow: 1, padding: '20px' }}>
                        <Box sx={{ margin: 2, textAlign: 'center' }}>
                            <Typography variant="h2" gutterBottom>
                                Paramètres de l'application
                            </Typography>
                        </Box>
                        <Container sx={{ mt: 5, width: '100%'}}>                    
                            <AlertComponent />
                            <LoadingIndicator />        
                            {renderContent()}
                        </Container>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ParametersPage;