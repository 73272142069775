import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { Container, TextField, Button, Box, Grid, Avatar } from '@mui/material';
import CustomModal from '../common/modal/CustomModal';
import { changePassword } from '../../redux/actions/usersActions';
import { format } from 'date-fns';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';
import * as yup from 'yup';
import { updateProfile } from '../../redux/actions/authActions';

const schema = yup.object().shape({
  newPassword: yup.string().min(8, 'Le mot de passe doit comporter au moins 6 caractères').required('Le nouveau mot de passe est requis')
});

const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialFormData = useMemo(() => ({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    birthdate: user?.birthdate ? format(new Date(user.birthdate), "yyyy-MM-dd") : '',
    phoneNumber: user?.phoneNumber || '',
    profilePictureUrl: user?.profilePictureUrl || '',
    firstLogin: user?.firstLogin ? format(new Date(user.firstLogin), "yyyy-MM-dd'T'HH:mm") : '',
    lastLogin: user?.lastLogin ? format(new Date(user.lastLogin), "yyyy-MM-dd'T'HH:mm") : ''
  }), [user]);

  const initialPasswordData = useMemo(() => ({
    idUser: user?.id || 0,
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  }), [user?.id]);

  const [formData, setFormData] = useState(initialFormData);
  const [passwordData, setPasswordData] = useState(initialPasswordData);

  useEffect(() => {
    if (isModalOpen) {
      setFormData(initialFormData);
      setPasswordData(initialPasswordData);
    }
  }, [isModalOpen, initialFormData, initialPasswordData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(updateProfile(userToUpdate));
  };

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await schema.validate(passwordData);
      dispatch(changePassword(passwordData));
      handleModalClose();
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const userToUpdate = useMemo(() => ({
    id: user?.id || 0,
    role: user?.role || '',
    enabled: user?.enabled || false,
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    birthdate: formData.birthdate ? new Date(formData.birthdate) : undefined,
    phoneNumber: formData.phoneNumber,
    profilePictureUrl: formData.profilePictureUrl,
  }), [user?.id, user?.role, user?.enabled, formData]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Container>
          <Box component="form" onSubmit={onSubmit} sx={{ m: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Avatar src={formData.profilePictureUrl} alt="Profile Picture" sx={{ width: 100, height: 100, margin: 'auto' }} />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <TextField
                  fullWidth
                  label="Prénom"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <TextField
                  fullWidth
                  label="Nom"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="outlined"
                  type="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Date de naissance"
                  name="birthdate"
                  value={formData.birthdate}
                  onChange={handleChange}
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Numéro de téléphone"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="URL de l'image de profil"
                  name="profilePictureUrl"
                  value={formData.profilePictureUrl}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Première connexion"
                  name="firstLogin"
                  value={formData.firstLogin}
                  variant="outlined"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Dernière connexion"
                  name="lastLogin"
                  value={formData.lastLogin}
                  variant="outlined"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={handleModalOpen} variant="outlined" color="primary" fullWidth>
                  Changer le mot de passe
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Mettre à jour
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <CustomModal
          open={isModalOpen}
          title="Changer le mot de passe"
          onClose={handleModalClose}
        >
          <form onSubmit={handleChangePassword}>
            <TextField
              fullWidth
              label="Mot de passe actuel"
              name="currentPassword"
              value={passwordData.currentPassword}
              onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
              variant="outlined"
              type="password"
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Nouveau mot de passe"
              name="newPassword"
              value={passwordData.newPassword}
              onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
              variant="outlined"
              type="password"
              margin="normal"
              id="password"
              autoComplete="current-password"
              required
            />
            <TextField
              fullWidth
              label="Confirmer le nouveau mot de passe"
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
              variant="outlined"
              type="password"
              margin="normal"
              required
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Changer le mot de passe
            </Button>
          </form>
        </CustomModal>
      </Box>
    </Box>
  );
};

export default ProfilePage;
