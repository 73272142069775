import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/api';
import { CustomerType } from '../types/customers';
import { 
  fetchCustomersRequest,fetchCustomersSuccess,fetchCustomersFailure, 
  addCustomersRequest, addCustomersSuccess, addCustomersFailure, 
  deleteCustomerRequest, deleteCustomerSuccess, deleteCustomerFailure,
  initCustomerServiceRequest,initCustomerServiceSuccess,initCustomerServiceFailure,
  selectSelectedCustomer,
  updateCustomerRequest,updateCustomerSuccess,updateCustomerFailure,
  updateCustomerServiceRequest,updateCustomerServiceSuccess,updateCustomerServiceFailure,
  deleteCustomerServiceRequest,deleteCustomerServiceSuccess, deleteCustomerServiceFailure

} from '../reducers/custReducer';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';
import { CustomerServiceType } from '../types/services';

export const selectCustomer = createAsyncThunk(
  'cust/selectCustomer',
  async (customer: CustomerType, { dispatch }) => {
    dispatch(selectSelectedCustomer(customer));
  }
);

export const updateCustomerService = createAsyncThunk(
  'cust/updateCustomerService',
  async (customerService: CustomerServiceType, { dispatch }) => {
      try {
        dispatch(updateCustomerServiceRequest());
        const response = await axiosInstance.post('/v1/customer/updateCustomerService', customerService);
        const data: CustomerServiceType = response.data;
        dispatch(updateCustomerServiceSuccess(data));
        dispatch(setAlertWithDuration('Mise a jour effectuée', 'success', '200'));
      } catch (error :any) {
        const errorMessage = error.response?.data?.message || error.message;
        const errorCode = error.response?.status;
        dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
        dispatch(updateCustomerServiceFailure(errorMessage));
      }
  }
);

export const fetchCustomers = createAsyncThunk(
  'cust/fetchCustomers',
  async (_, { dispatch }) => {
      try {
        dispatch(fetchCustomersRequest());
        const response = await axiosInstance.get('/v1/customer');
        const data: CustomerType[] = response.data;
        dispatch(fetchCustomersSuccess(data));
      } catch (error :any) {
        const errorMessage = error.response?.data?.message || error.message;
        const errorCode = error.response?.status;
        dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
        dispatch(fetchCustomersFailure(errorMessage));
      }
  }
);

export const initializeCustomService = createAsyncThunk(
  'cust/initializeCustomService',
  async ({ customerId, serviceId }: { customerId: number; serviceId: number }, { dispatch }) => {
    dispatch(initCustomerServiceRequest());
    try {
      const response = await axiosInstance.post('/v1/customer/initialize-custom-service', { customerId, serviceId });
      const updateCustomer: CustomerType = response.data;
      dispatch(initCustomerServiceSuccess({ customer: updateCustomer }));
      dispatch(setAlertWithDuration('Initialisation réussie', 'success', '200'));
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(initCustomerServiceFailure(errorMessage));
    }
  }
);

export const addCustomer = createAsyncThunk(
  'cust/addCustomer',
  async (customers: CustomerType[], { dispatch }) => {
    try {
      dispatch(addCustomersRequest());
      const responses = await axiosInstance.post('/v1/customer', customers);
      const customersWithIds = responses.data.map((customer: any, index: number) => ({
        ...customers[index],
        id: customer.id
      }));
      dispatch(addCustomersSuccess(customersWithIds));
      return customersWithIds; // Retourne les clients avec leurs identifiants
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(addCustomersFailure(errorMessage));
    }
  }
);


export const deleteCustomer = createAsyncThunk(
  'cust/deleteCustomer',
  async (id: number, { dispatch }) => {
    try {
      dispatch(deleteCustomerRequest());
      await axiosInstance.delete(`/v1/customer/${id}`);
      dispatch(deleteCustomerSuccess(id));
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(deleteCustomerFailure(errorMessage));
    }
  }
);


export const updateCustomer = createAsyncThunk(
  'cust/updateCustomer',
  async (customer: CustomerType, { dispatch }) => {
    try {
      dispatch(updateCustomerRequest());
      const updatedCustomer = await axiosInstance.put(`/v1/customer/${customer.id}`, customer);
      dispatch(updateCustomerSuccess(updatedCustomer.data)); 
      dispatch(setAlertWithDuration("Mise a jour effectuée", 'success', '200'));
      return updatedCustomer.data.data;
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(updateCustomerFailure(error));
    }
  }
);

export const deleteCustomerService = createAsyncThunk(
  'cust/deleteCustomerService',
  async ({ customerId , customerServiceId }: { customerId: number; customerServiceId: number }, { dispatch }) => {
    try {
      dispatch(deleteCustomerServiceRequest());
      await axiosInstance.delete(`/v1/customer/${customerId}/delete-custom-service/${customerServiceId}`);
      dispatch(deleteCustomerServiceSuccess({ customerId , customerServiceId })); 
      dispatch(setAlertWithDuration("Mise a jour effectuée", 'success', '200'));
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(deleteCustomerServiceFailure(error));
    }
  }
);