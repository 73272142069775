import React from 'react';
import { Typography, Grid, FormControlLabel, Checkbox, List, ListItem } from '@mui/material';
import { ServiceType } from '../../redux/types/services';

interface AddServiceFormProps {
  services: ServiceType[];
  selectedServices: ServiceType[];
  handleCardClick: (service: ServiceType) => void;
  handleServiceToggle: (service: ServiceType) => void;
}

const AddServiceForm: React.FC<AddServiceFormProps> = ({ services, selectedServices, handleCardClick, handleServiceToggle }) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
        Choix des prestations
      </Typography>

      <List>
        {services.map((service) => (
          <ListItem disablePadding>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedServices.some((selectedService) => selectedService.id === service.id)}
                  onChange={() => handleServiceToggle(service)}
                />
              }
              label={<Typography variant="body2">{service.name}</Typography>}
              onClick={(e) => e.stopPropagation()}
            />
          </ListItem>
          
        ))} 
      </List>
    </Grid>
  );
};

export default AddServiceForm;
