import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface ConfirmModalProps {
  open: boolean;
  title?: string;
  message?: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ open, title, message, handleClose, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title || 'Confirmer l\'action'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message || 'Êtes-vous sûr de vouloir effectuer cette action ?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
