import React, { useState } from 'react';
import { useAppDispatch } from '../../redux/store/store';
import { login, resetPassword } from '../../redux/actions/authActions';
import { Box, Container, Typography, TextField, Button, Grid, Link, IconButton, Card } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import theme from '../../shared/theme/theme';
import logo from '../../shared/assets/logo.svg'; 
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomModal from '../common/modal/CustomModal';
import { useNavigate } from 'react-router-dom';
import AlertComponent from '../common/Alert';
import SignupModal from '../auth/SignupModal';
import packageJson from '../../../package.json';
import LoadingIndicator from '../common/LoadingIndicator';

const loginSchema = yup.object().shape({
  email: yup.string().email('Veuillez entrer une adresse email valide').required('L\'email est obligatoire'),
  password: yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
});

const resetSchema = yup.object().shape({
  email: yup.string().email('Veuillez entrer une adresse email valide').required('L\'email est obligatoire'),
});

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false); 
  const [openSignupModal, setOpenSignupModal] = useState(false);
  
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const { register: registerReset, handleSubmit: handleSubmitReset, formState: { errors: resetErrors }, reset: resetReset } = useForm({
    resolver: yupResolver(resetSchema),
  });

  const dispatch = useAppDispatch();

  const onSubmit = async (data: { email: string; password: string }) => {
    const transformedData = {
      ...data,
      email: data.email.toLowerCase(),
    };
    const result = await dispatch(login({ email: transformedData.email, password: transformedData.password }));
    if (login.fulfilled.match(result)) {
      reset();
      navigate('/', { replace: true });
    }
  };

  const onResetSubmit = async (data: { email: string }) => {
    await dispatch(resetPassword({ email: data.email }));
    resetReset();
    reset();
    setOpenResetModal(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleOpenResetModal = () => {
    setOpenResetModal(true);
  };

  const handleCloseResetModal = () => {
    setOpenResetModal(false);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ paddingX: '5%', paddingY: '2%' }}>
      <AlertComponent/>
      <LoadingIndicator />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
          boxShadow: 1,
          borderRadius: 2,
          padding: 2,
          '@media (max-width: 600px)': {
            padding: 1,
            marginTop: 2,
          },
        }}
      >
        <img src={logo} alt="Logo" style={{ width: '50%', height: 'auto' }} />
        <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mt: 2 }}>
          Se connecter
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse email"
            autoComplete="email"
            autoFocus
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register('email')}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Mot de passe"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password')}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: theme.palette.primary.main, '&:hover': { backgroundColor: theme.palette.primary.dark } }}
          >
            Se connecter
          </Button>
          <Grid container direction="column" alignItems="flex-end">
            <Grid item xs>
              <Link component="button" variant="body2" onClick={handleOpenResetModal}>
                Mot de passe oublié ?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={() => setOpenSignupModal(true)}>
                Vous n'avez pas de compte ? Inscrivez-vous
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SignupModal open={openSignupModal} onClose={() => setOpenSignupModal(false)} />
      <CustomModal title={"Réinitialiser le mot de passe"} open={openResetModal} onClose={handleCloseResetModal}>
        <form onSubmit={handleSubmitReset(onResetSubmit)}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="reset-email"
              label="Adresse email"
              autoComplete="email"
              autoFocus
              error={!!resetErrors.email}
              helperText={resetErrors.email?.message}
              {...registerReset('email')}
            />
            <Button type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
              Réinitialiser le mot de passe
            </Button>
        </form>
      </CustomModal>
      <Card
        sx={{
          position: 'fixed',
          bottom: theme.spacing(2),
          left: '50%',
          transform: 'translateX(-50%)',
          width: '90%',
          boxShadow: 3,
          '@media (min-width: 600px)': {
            width: 200,
          },
        }}
      >
        <Typography variant="body2" color="textSecondary" align="center">
          Version: {packageJson.version}
        </Typography>
      </Card>
    </Container>
  );
};

export default LoginPage;
