import { fetchServicesRequest, fetchServicesFailure, fetchServicesSuccess, updateServicesRequest, updateServicesSuccess, updateServicesFailure } from '../reducers/servicesReducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/api';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';
import { ServiceType } from '../types/services';

export const fetchServices = createAsyncThunk(
    'services/fetchServices',
    async (_, { dispatch }) => {
        try {
          dispatch(fetchServicesRequest());
          const response = await axiosInstance.get('/v1/services');
          dispatch(fetchServicesSuccess(response.data));
        } catch (error :any) {
          const errorMessage = error.response?.data?.message || error.message;
          const errorCode = error.response?.status; 
          dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
          dispatch(fetchServicesFailure(errorMessage)); 
        }
    }
)


export const updateService = createAsyncThunk(
  'services/updateService',
  async (service: ServiceType, { dispatch }) => {
    try {
      dispatch(updateServicesRequest());
      const response = await axiosInstance.put('/v1/services', service);
      dispatch(updateServicesSuccess(response.data));
      dispatch(setAlertWithDuration("Enregistrement effectué", 'success', '200'));
    } catch (error :any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(updateServicesFailure(errorMessage));
    }
  }
)
