import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Box } from '@mui/material';
import CustomModal from '../common/modal/CustomModal';
import { CustomerType } from '../../redux/types/customers';

interface CustomerSelectionModalProps {
  open: boolean;
  onClose: () => void;
  customers: CustomerType[];
  onSelectCustomer: (customerId: number) => void;
}

const CustomerSelectionModal: React.FC<CustomerSelectionModalProps> = ({
  open,
  onClose,
  customers,
  onSelectCustomer,
}) => {
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | ''>('');

  const handleCustomerSelect = (customerId: number) => {
    setSelectedCustomerId(customerId);
  };

  const handleConfirm = () => {
    if (selectedCustomerId !== '') {
      onSelectCustomer(selectedCustomerId);
    }
    onClose();
  };

  return (
    <CustomModal open={open} title="Sélectionner un client" onClose={onClose}>
      <FormControl fullWidth variant="outlined" style={{ marginTop: '16px' }}>
        <InputLabel id="customer-label">Client</InputLabel>
        <Select
          labelId="customer-label"
          value={selectedCustomerId}
          onChange={(e) => handleCustomerSelect(Number(e.target.value))}
          label="Client"
        >
          {customers.map((customer) => (
            <MenuItem key={customer.id} value={customer.id}>
              {customer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box textAlign="center" mt={2}>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          Confirmer
        </Button>
      </Box>
    </CustomModal>
  );
};

export default CustomerSelectionModal;
