import { useNavigate } from "react-router-dom";
import AlertComponent from "../common/Alert";
import LoadingIndicator from "../common/LoadingIndicator";
import { Box, Container, Typography } from "@mui/material";
import AddCustomerForm from "./AddCustomerForm";
import MenuDrawer from "../common/Drawer/MenuDrawer";

const AddCustomerPage = () => {
    const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex' }}> {/* Ajouter un conteneur flexible */}
      <MenuDrawer />
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <AlertComponent />
        <LoadingIndicator />
        <Container>
            <Box textAlign="center" my={4}>
            <Typography variant="h4" gutterBottom>
                Informations sur le client
            </Typography>
            </Box>
            <AddCustomerForm
                onClose={() => {
                    navigate(`/customers`, { replace: true });
                }}
        />
        </Container>
        </Box>
    </Box>
  );
};

export default AddCustomerPage;
