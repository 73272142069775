import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { ServiceParameterType } from '../../redux/types/services';

interface ServicesParametersProps {
  selectedParams: ServiceParameterType[] | null;
  handleParameterChange: (
    paramIndex: number,
    nameValue: keyof ServiceParameterType,
    newValue: string
  ) => void;
}

const ServicesParameters: React.FC<ServicesParametersProps> = React.memo(({ selectedParams, handleParameterChange }) => {
  const [localParams, setLocalParams] = useState<ServiceParameterType[]>([]);

  // Mettre à jour localParams lorsque selectedParams change
  useEffect(() => {
    if (selectedParams) {
      setLocalParams(selectedParams.map(param => ({ ...param })));
    } else {
      setLocalParams([]);
    }
  }, [selectedParams]);

  const handleFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, paramIndex: number, nameValue: keyof ServiceParameterType) => {
      const { value } = e.target;
      // Mettre à jour l'état local avec la nouvelle valeur sous forme de chaîne
      setLocalParams(prevLocalParams => {
        const updatedParams = [...prevLocalParams];
        updatedParams[paramIndex] = {
          ...updatedParams[paramIndex],
          [nameValue]: value
        };
        return updatedParams;
      });
    },
    []
  );

  const handleFieldBlur = useCallback(
    (paramIndex: number, nameValue: keyof ServiceParameterType) => {
      // Vérifier que localParams[paramIndex] est défini
      if (localParams[paramIndex]) {
        // Récupérer la valeur mise à jour du champ local
        const updatedValue = localParams[paramIndex][nameValue];

        // Vérifier que updatedValue est une chaîne de caractères
        if (typeof updatedValue === 'string') {
          // Remplacer les virgules par des points
          const cleanedValue = updatedValue.replace(',', '.');

          // Convertir en nombre ou NaN si non valide
          const newValue = parseFloat(cleanedValue);

          // Vérifier si la valeur est un nombre valide
          if (!isNaN(newValue)) {
            // Appeler handleParameterChange avec la valeur mise à jour
            handleParameterChange(paramIndex, nameValue, cleanedValue); // Passer la valeur nettoyée ici
          }
        }
      }
    },
    [localParams, handleParameterChange]
  );

  // Affichage du composant
  return (
    <Accordion>
      <AccordionSummary>
        <Typography variant="h6">Paramètres</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {selectedParams && selectedParams.length > 0 ? (
          <Grid container spacing={2}>
            {selectedParams.map((param, index) => (
              <Grid item xs={12} sm={6} key={param.id}>
                <TextField
                  label={param.description}
                  value={localParams[index]?.value || ''} // Assurez-vous d'accéder à la bonne propriété
                  onChange={(e) => handleFieldChange(e, index, 'value')}
                  onBlur={() => handleFieldBlur(index, 'value')}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>Aucun paramètre disponible.</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
});

export default ServicesParameters;
