import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceType } from '../types/services';
import { RootState } from '../store/store';

interface ServicesState {
    services: ServiceType[];
    loading: boolean;
    error: string | null;
}

const initialState: ServicesState = {
    services: [],
    loading: false,
    error: null,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    fetchServicesRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchServicesSuccess: (state, action: PayloadAction<ServiceType[]>) => {
      state.loading = false;
      state.services = action.payload;
    },
    fetchServicesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateServicesRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateServicesSuccess: (state, action: PayloadAction<ServiceType>) => {
      state.loading = false;
      state.loading = false;
      const { id } = action.payload;
      const index = state.services.findIndex((Service) => Service.id === id);
      if (index !== -1) {
        state.services[index] = action.payload;
      }
    },
    updateServicesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchServicesRequest, fetchServicesSuccess, fetchServicesFailure, updateServicesRequest, updateServicesSuccess, updateServicesFailure } = servicesSlice.actions;

export const selectServicesLoading = (state: RootState) => state.services.loading;

export default servicesSlice.reducer;