import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store/store';
import { fetchUsers, updateUserStatus } from '../../redux/actions/usersActions';
import { RootState } from '../../redux/store/store';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { Box, IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';

const UsersPage = () => {
  const dispatch = useAppDispatch();
  const users = useSelector((state: RootState) => state.users.users);
  const userActive = useSelector((state: RootState) => state.auth.user);
  const isAdmin = userActive?.role === 'ADMIN';

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const filteredUsers = users.filter(user => user.id !== userActive?.id);

  const handleUpdateStatus = (userId: number, currentStatus: boolean) => {
    const newStatus = !currentStatus;
    dispatch(updateUserStatus({userId: userId, newStatus: newStatus}));
  };

  const columns: MUIDataTableColumnDef[] = [
    { name: "id", label: "ID", options: { display: 'false', filter: false } },
    { name: "lastName", label: "Nom" },
    { name: "firstName", label: "Prénom" },
    { name: "email", label: "Email" },
    { name: "role", label: "Rôle" },
    { name: "enabled", label: "Actif", options: { display: 'false', filter: false } },
    ...(isAdmin ? [{
      name: "actions", label: "Actions",
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          const userId = tableMeta.rowData[0];
          const isActive = tableMeta.rowData[5];
          return (
            <IconButton onClick={() => handleUpdateStatus(userId, isActive)}>
              {isActive ? <GridCloseIcon color="error" /> : <CheckIcon color="primary" />}
            </IconButton>
          );
        },
        filter: false,
        sort: false,
      }
    }] : [])
  ];
  
  
  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: 'none',
    elevation: 0,
    sortOrder: {
      name: 'id',
      direction: 'desc'
    },
    textLabels: {
      pagination: {
        rowsPerPage: "Lignes par page:",
      },
      filter: {
        all: "Tous",
        title: "Mes filtres",
        reset: "Réinitialiser",          
      },
      toolbar: {
        viewColumns: "Affichage",
        filterTable: "Filtrer",
        search: "Rechercher"
      },
      viewColumns: {
        title: ""
      }
    }
  };

  return (
    <Box>
        <Box sx={{ margin: '0 auto'}}>
          <MUIDataTable
            title={""}
            data={filteredUsers}
            columns={columns}
            options={options}
          />
        </Box>
    </Box>
  );
};

export default UsersPage;
