import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/api';
import { NotificationType } from '../types/notifications';

export const fetchNotifications = createAsyncThunk(
    'notifications/fetchNotifications',
    async (userId: number) => {
        const response = await axiosInstance.get<NotificationType[]>(`/v1/notifications/${userId}/unread`);
        return response.data;
    }
);

export const deleteNotification = createAsyncThunk(
    'notifications/deleteNotification',
    async (notificationId: number) => {
        await axiosInstance.delete(`/v1/notifications/${notificationId}`);
        return notificationId;
    }
);