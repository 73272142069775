import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { LogsData } from "../types/types";
import { fetchLogs } from "../actions/logsActions";

interface LogsState {
    result: LogsData[];
    loading: boolean;
    error: string | null;
}

const initialState: LogsState = {
    result: [],
    loading: false,
    error: null,
};

const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchLogs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLogs.fulfilled, (state, action: PayloadAction<LogsData[]>) => {
                state.loading = false;
                state.result = action.payload;
            })
            .addCase(fetchLogs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur s\'est produite lors de la récupération des logs.';
            })
    }
});

export const selectLogsLoading = (state: RootState) => state.logs.loading;

export default logsSlice.reducer;
