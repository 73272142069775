import React, { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { QuoteStatusType } from '../../redux/types/quotes';

type QuoteStatusSelectProps = {
  statuses: QuoteStatusType[];
  currentStatusId: number | '';
  onSelectStatus: (statusId: number) => void;
};

const QuoteStatusSelect: React.FC<QuoteStatusSelectProps> = ({ statuses, currentStatusId, onSelectStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState<number | ''>(currentStatusId);

  return (
    <Box m={3} display="flex" justifyContent="center">
      <FormControl variant="standard" fullWidth sx={{ width: '30%' }}>
        <InputLabel id="status-select-label">Statut</InputLabel>
        <Select
          labelId="status-select-label"
          value={selectedStatus}
          onChange={(event) => {
            const statusId = event.target.value as number;
            setSelectedStatus(statusId);
            onSelectStatus(statusId);
          }}
        >
          <MenuItem value="">
            <em>Choisir...</em>
          </MenuItem>
          {statuses.map((status) => (
            <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default QuoteStatusSelect;
