import React, { FC } from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface CalculationResultDisplayProps {
  result: {
    isValid: boolean;
    errors?: string[];
    warnings?: string[];
    price: number;
    area: number | null;
  };
}

const CalculationResultDisplay: FC<CalculationResultDisplayProps> = ({ result }) => {
  return (
    <Box mt={2}>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2} alignItems="center">
        {Array.isArray(result?.warnings) && result?.warnings.length > 0 && (
          <Grid item xs={12}>
            {result?.warnings.map((warn, index) => (
              <Typography key={index} variant="body2" sx={{ mt: 1, color: 'orange' }}>
                {warn}
              </Typography>
            ))}
          </Grid>
        )}
        {Array.isArray(result?.errors) && result?.errors.length > 0 && (
          <Grid item xs={12}>
            {result?.errors.map((error, index) => (
              <Typography key={index} variant="body2" sx={{ mt: 1, color: '#d32f2f' }}>
                {error}
              </Typography>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <Box textAlign="right">
            {result.isValid ? (
              <Typography variant="body1" sx={{ color: 'green' }}>
                <CheckCircleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                Saisie valide
              </Typography>
            ) : (
              <Typography variant="body1" sx={{ color: '#d32f2f' }}>
                <ErrorIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                Saisie invalide
              </Typography>
            )}
            {result.area !== null && result.area !== undefined && (
              <Typography variant="body2" sx={{ mt:1 }}>
                Surface: {result.area} m²
              </Typography>
            )}
            <Typography variant="h4" sx={{ mt:1 }}>
              Montant: {result.price || '0'} €
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalculationResultDisplay;
