import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { selectCustomersLoading } from '../../redux/reducers/custReducer';
import { selectQuotesLoading } from '../../redux/reducers/quoteReducer';
import { RootState } from '../../redux/store/store';
import { selectAuthLoading } from '../../redux/reducers/authReducer';
import { selectServicesLoading } from '../../redux/reducers/servicesReducer';
import { selectUsersLoading } from '../../redux/reducers/usersReducer';
import { selectChartsLoading } from '../../redux/reducers/chartsReducer';

const LoadingIndicator: React.FC = () => {
    const customersLoading = useSelector((state: RootState) => selectCustomersLoading(state));
    const quotesLoading = useSelector((state: RootState) => selectQuotesLoading(state));
    const authLoading = useSelector((state: RootState) => selectAuthLoading(state));
    const serviceLoading = useSelector((state: RootState) => selectServicesLoading(state));
    const usersLoading = useSelector((state: RootState) => selectUsersLoading(state));
    const chartsLoading = useSelector((state: RootState) => selectChartsLoading(state));

    const isLoading = customersLoading || quotesLoading || authLoading || serviceLoading || usersLoading || chartsLoading;

    return (
        <Backdrop open={isLoading} style={{ zIndex: 1300 }}>
        <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default LoadingIndicator;
