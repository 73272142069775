import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChartsData, LineChartData, PieChartData } from "../types/charts/pieChart";
import { fetchLineChart, fetchNextQuoteToExpire, fetchNextToPayments, fetchPieChart } from '../actions/chartsActions';
import { RootState } from '../store/store';
import { QuoteResumeType } from '../types/quotes';

interface ChartsState {
    result: ChartsData;
    loading: boolean;
    error: string | null;
}

const initialState: ChartsState = {
    result: { pieChart: [], lineChart: [], nextExpire: [], nextPayments: [] },
    loading: false,
    error: null,
};

const chartsSlice = createSlice({
    name: 'charts',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPieChart.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPieChart.fulfilled, (state, action: PayloadAction<PieChartData[]>) => {
                state.loading = false;
                state.result.pieChart = action.payload;
            })
            .addCase(fetchPieChart.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur s\'est produite lors de la récupération des données du PieChart.';
            })
            .addCase(fetchLineChart.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLineChart.fulfilled, (state, action: PayloadAction<LineChartData[]>) => {
                state.loading = false;
                state.result.lineChart = action.payload;
            })
            .addCase(fetchLineChart.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur s\'est produite lors de la récupération des données du LineChart.';
            })
            .addCase(fetchNextQuoteToExpire.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNextQuoteToExpire.fulfilled, (state, action: PayloadAction<QuoteResumeType[]>) => {
                state.loading = false;
                state.result.nextExpire = action.payload;
            })
            .addCase(fetchNextQuoteToExpire.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur s\'est produite lors de la récupération des données des prochains devis expirés.';
            })
            .addCase(fetchNextToPayments.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNextToPayments.fulfilled, (state, action: PayloadAction<QuoteResumeType[]>) => {
                state.loading = false;
                state.result.nextPayments = action.payload;
            })
            .addCase(fetchNextToPayments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur s\'est produite lors de la récupération des données des prochains devis paiements.';
            })
    }
});

export const selectChartsLoading = (state: RootState) => state.charts.loading;

export default chartsSlice.reducer;
