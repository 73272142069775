import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  exp: number; // Date d'expiration du token (en secondes depuis l'Unix Epoch)
}

export const isTokenValid = (token: string): boolean => {
  try {
    const decodedToken: DecodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Temps actuel en secondes depuis l'Unix Epoch
    return decodedToken.exp > currentTime;
  } catch (error) {
    console.error('Erreur lors de la vérification de la validité du token JWT :', error);
    return false;
  }
};
