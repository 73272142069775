import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { ServicePriceType } from '../../redux/types/services';

interface ServicesPricesProps {
  selectedPrices: ServicePriceType[] | null;
  handlePriceChange: (
    priceIndex: number,
    nameValue: keyof ServicePriceType,
    newValue: number
  ) => void;
}

const ServicesPrices: React.FC<ServicesPricesProps> = React.memo(({ selectedPrices, handlePriceChange }) => {
  const [localPrices, setLocalPrices] = useState<ServicePriceType[]>([]);

  // Mettre à jour localPrices lorsque selectedPrices change
  useEffect(() => {
    if (selectedPrices) {
      setLocalPrices(selectedPrices.map(price => ({ ...price })));
    } else {
      setLocalPrices([]);
    }
  }, [selectedPrices]);

  const handleFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, priceIndex: number, nameValue: keyof ServicePriceType) => {
      const { value } = e.target;

      // Mettre à jour l'état local avec la nouvelle valeur sous forme de chaîne
      setLocalPrices(prevLocalPrices => {
        const updatedPrices = [...prevLocalPrices];
        updatedPrices[priceIndex] = {
          ...updatedPrices[priceIndex],
          [nameValue]: value
        };
        return updatedPrices;
      });
    },
    []
  );

  const handleFieldBlur = useCallback(
    (priceIndex: number, nameValue: keyof ServicePriceType) => {
      // Récupérer la valeur mise à jour du champ local
      const updatedValue = localPrices[priceIndex][nameValue];
  
      // Vérifier que updatedValue est une chaîne de caractères
      if (typeof updatedValue === 'string') {
        // Remplacer les virgules par des points
        const cleanedValue = updatedValue.replace(',', '.');
  
        // Convertir en nombre ou NaN si non valide
        const newValue = parseFloat(cleanedValue);
  
        // Vérifier si la valeur est un nombre valide
        if (!isNaN(newValue)) {
          // Appeler handlePriceChange avec la valeur mise à jour
          handlePriceChange(priceIndex, nameValue, newValue);
        }
      }
    },
    [localPrices, handlePriceChange]
  );
  

  const renderPriceTable = useCallback(() => {
    if (!localPrices || localPrices.length === 0) {
      return <Typography>Aucun prix disponible.</Typography>;
    }

    const { value1_name, value2_name, value3_name } = localPrices[0];
    const hasValue3 = !!value3_name;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>{value1_name}</TableCell>
            <TableCell>{value2_name}</TableCell>
            {hasValue3 && <TableCell>{value3_name}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {localPrices.map((price, priceIndex) => (
            <TableRow key={price.id}>
              <TableCell>{price.type}</TableCell>
              <TableCell>
                <TextField
                  name={`value1_${priceIndex}`}
                  value={price.value1}
                  onChange={(e) => handleFieldChange(e, priceIndex, 'value1')}
                  onBlur={() => handleFieldBlur(priceIndex, 'value1')}
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </TableCell>
              <TableCell>
                <TextField
                  name={`value2_${priceIndex}`}
                  value={price.value2}
                  onChange={(e) => handleFieldChange(e, priceIndex, 'value2')}
                  onBlur={() => handleFieldBlur(priceIndex, 'value2')}
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </TableCell>
              {hasValue3 && (
                <TableCell>
                  <TextField
                    name={`value3_${priceIndex}`}
                    value={price.value3}
                    onChange={(e) => handleFieldChange(e, priceIndex, 'value3')}
                    onBlur={() => handleFieldBlur(priceIndex, 'value3')}
                    margin="normal"
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }, [localPrices, handleFieldChange, handleFieldBlur]);

  return (
    <Accordion>
      <AccordionSummary>
        <Typography variant="h6">Prix</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {renderPriceTable()}
      </AccordionDetails>
    </Accordion>
  );
});

export default ServicesPrices;
