import React, { FC, useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, Grid, TextField, MenuItem, Select, SelectChangeEvent, FormControl, InputLabel, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { DosCarreColleParametersType } from '../../../../redux/types/services/serviceParameters';
import { clearCalculationResult } from '../../../../redux/reducers/calculReducer';
import { calculationRow } from '../../../../redux/actions/calculActions';
import { updateQuoteRow } from '../../../../redux/actions/quotesActions';
import CalculationResultDisplay from '../CalculationResultDisplay';
import { QuoteRowType } from '../../../../redux/types/quotes';
import PopoverDetails from '../../PopoverDetails';
import CancelIcon from '@mui/icons-material/Cancel';

interface DosCarreColleProps{
  open: boolean;
  row: QuoteRowType;
  quoteId: number;
  handleClose: () => void;
}


const DosCarreColleModal: FC<DosCarreColleProps> = ({ open, row,quoteId, handleClose }) => {
  const dispatch = useAppDispatch();

  const result = useSelector((state: RootState) => state.calcul.result);

  const [formValues, setFormValues] = useState<DosCarreColleParametersType>({
    type: 'dosCarreColle',
    description: row.description || '',
    price: row.price || 0,
    overloadPrice: row.overloadPrice || 0,
    rowId: row.id || 0,
    serviceId: row.serviceId,
    quoteId: quoteId,
    sheetWidth: row.sheetWidth,
    sheetLength: row.sheetLength,
    sheetsNumber: row.nbExemplaire || 0,
    typePapierInterieur: row.typePapierInterieur || '',
    grammagePapierInterieur: row.grammagePapierInterieur || 0,
    typePapierCouverture: row.typePapierCouverture || '',
    grammagePapierCouverture: row.grammagePapierCouverture || 0,
    nbExemplaire: row.nbExemplaire || 0,
    nbPageInterieur: row.nbPageInterieur || 0,
    nbPageCouverture: row.nbPageCouverture || 0,
    nbRainage: row.nbRainage || 0,
    nbVersions: row.nbVersions || 0,
    nbDispatch: row.nbDispatch || 0,
    descriptionPreencartage: row.descriptionPreencartage || '',
    imposition: {
      nbPoste32: row.nbPoste32 || 0,
      nbPoste24: row.nbPoste24 || 0,
      nbPoste16: row.nbPoste16 || 0,
      nbPoste12: row.nbPoste12 || 0,
      nbPoste8: row.nbPoste8 || 0,
      nbPoste6: row.nbPoste6 || 0,
      nbPoste4: row.nbPoste4 || 0,
    },
    collage: false,
    cousu: row.cousu || false,
    prixCollage: 0,
  });

  useEffect(() => {
    if (open) {
      dispatch(clearCalculationResult());
    }
  }, [open, dispatch]);


  useEffect(() => {
    if (row.id !== null && quoteId !== null) {
      dispatch(calculationRow({
        quoteId,
        rowId: row.id || 0,
        serviceParameters:formValues
      }))
    }
  }, [formValues, row.id, quoteId, dispatch]);

  const handleChange = (field: string, value: string | number | boolean) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };
  
  const handleFieldChangeImposition = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(event.target.value);
  
    if (isNaN(newValue) || newValue === null) {
      newValue = 0;
    }
  
    setFormValues(prevValues => ({
      ...prevValues,
      imposition: {
        ...prevValues.imposition,
        [field]: newValue
      }
    }));
  };

  const handleCheckboxChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(field, event.target.checked);
  };
  
  const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(field, event.target.value);
  };

  const handleSelectChange = (field: string) => (event: SelectChangeEvent<string>) => {
    handleChange(field, event.target.value);
  };

  const handleSave = () => {
    if (row.id !== null && quoteId !== null) {
      formValues.price = result?.price || 0;
      formValues.sheetsNumber = formValues.nbExemplaire;
      dispatch(updateQuoteRow({ quoteId, rowId: row.id || 0, serviceParameters: formValues }));
      handleClose();
    }
  };

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    >
      <Box
        sx={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          width: '90%',
          maxHeight: '90vh',
          p: 4,
          overflowY: 'auto',
          overflowX: 'hidden',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1
        }}
      >
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Dos Carre Colle
          </Typography>
        </Box>
        <Box sx={{ position: 'absolute', top: 16, right: 16, display: 'flex', alignItems: 'center' }}>
          <PopoverDetails details={result?.details || []} />
          <IconButton aria-label="cancel" onClick={handleClose}>
            <CancelIcon sx={{ color: '#d32f2f' }} />
          </IconButton>
        </Box>
        <Box sx={{ p: 2, borderRadius: 2 }}>
          <Typography variant="body1" sx={{ color: '#d32f2f' }} gutterBottom>
            Informations
          </Typography>
          {/* Section 1: Description et type */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Description"
                value={formValues.description}
                onChange={handleFieldChange('description')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" sx={{ color: '#d32f2f', mt: 1 }} gutterBottom>
                Format
              </Typography>
              <TextField
                label="Nombre d'exemplaire"
                size="small"
                value={formValues.nbExemplaire}
                onChange={handleFieldChange('nbExemplaire')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Largeur"
                size="small"
                value={formValues.sheetWidth}
                onChange={handleFieldChange('sheetWidth')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Longueur"
                size="small"
                value={formValues.sheetLength}
                onChange={handleFieldChange('sheetLength')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" sx={{ color: '#d32f2f', mt: 1 }} gutterBottom>
                Intérieur
              </Typography>
              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel>Type de papier intérieur</InputLabel>
                <Select
                  label="Type de papier intérieur"
                  onChange={handleSelectChange('typePapierInterieur')}
                  fullWidth
                  size="small"
                  value={formValues.typePapierInterieur}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="offset">Offset</MenuItem>
                  <MenuItem value="couche">Couché</MenuItem>
                  <MenuItem value="recycle">Recyclé</MenuItem>
                  <MenuItem value="volumineux">Volumineux</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Nombre de page intérieur"
                size="small"
                value={formValues.nbPageInterieur}
                onChange={handleFieldChange('nbPageInterieur')}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Grammage papier intérieur"
                value={formValues.grammagePapierInterieur}
                onChange={handleFieldChange('grammagePapierInterieur')}
                fullWidth
                size="small"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" sx={{ color: '#d32f2f', mt: 1 }} gutterBottom>
                Couverture
              </Typography>
              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel>Type de papier couverture</InputLabel>
                <Select
                  label="Type de papier couverture"
                  onChange={handleSelectChange('typePapierCouverture')}
                  fullWidth
                  size="small"
                  value={formValues.typePapierCouverture}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="chromo">Carton Chromo (ou Couché)</MenuItem>
                  <MenuItem value="brisol">Carton Bristol</MenuItem>
                  <MenuItem value="couche">Couché de Couverture</MenuItem>
                  <MenuItem value="kraft">Papier Kraft</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Nombre de page couverture"
                size="small"
                value={formValues.nbPageCouverture}
                onChange={handleFieldChange('nbPageCouverture')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
                sx={{ mb: 2 }}
              />
              
              <TextField
                label="Grammage papier couverture"
                value={formValues.grammagePapierCouverture}
                onChange={handleFieldChange('grammagePapierCouverture')}
                fullWidth
                size="small"
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 1, p: 2, border: '1px solid grey', borderRadius: 2 }}>
              <Typography variant="body1" sx={{ color: '#d32f2f', mb:1}} gutterBottom>
                Autres
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Pliage des couvertures avec leur rainage</InputLabel>
                  <Select
                    label="Pliage des couvertures avec leur rainage"
                    onChange={handleSelectChange('nbRainage')}
                    fullWidth
                    size="small"
                    value={formValues.nbRainage.toString()}
                    >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="3">3 volets</MenuItem>
                    <MenuItem value="4">4 volets</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Nombre versions"
                    size="small"
                    value={formValues.nbVersions}
                    onChange={handleFieldChange('nbVersions')}
                    fullWidth
                    inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Nombre de dispatch"
                    size="small"
                    value={formValues.nbDispatch}
                    onChange={handleFieldChange('nbDispatch')}
                    fullWidth
                    inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel label="Cousu" sx={{ mb: 2 }}
                    control={
                    <Checkbox
                      checked={formValues.cousu}
                      onChange={handleCheckboxChange('cousu')}
                    />}
                  />
                </Grid>
                {result && result.preEncartage && (
                  <Grid item xs={12}>
                    <TextField
                      label="Description pour le pré-encartage:"
                      size="small"
                      value={formValues.descriptionPreencartage}
                      onChange={handleFieldChange('descriptionPreencartage')}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 1, p: 2, border: '1px solid grey', borderRadius: 2 }}>
              <Typography variant="body1" sx={{ color: '#d32f2f', mb:1}} gutterBottom>
                Imposition
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="32 pages"
                        size="small"
                        value={formValues.imposition.nbPoste32}
                        onChange={handleFieldChangeImposition('nbPoste32')}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        label="24 pages"
                        size="small"
                        value={formValues.imposition.nbPoste24}
                        onChange={handleFieldChangeImposition('nbPoste24')}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        label="16 pages"
                        size="small"
                        value={formValues.imposition.nbPoste16}
                        onChange={handleFieldChangeImposition('nbPoste16')}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField
                        label="12 pages"
                        size="small"
                        value={formValues.imposition.nbPoste12}
                        onChange={handleFieldChangeImposition('nbPoste12')}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        label="8 pages"
                        size="small"
                        value={formValues.imposition.nbPoste8}
                        onChange={handleFieldChangeImposition('nbPoste8')}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField
                        label="6 pages"
                        size="small"
                        value={formValues.imposition.nbPoste6}
                        onChange={handleFieldChangeImposition('nbPoste6')}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        label="4 pages"
                        size="small"
                        value={formValues.imposition.nbPoste4}
                        onChange={handleFieldChangeImposition('nbPoste4')}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Montant en €"
              size="small"
              value={formValues.overloadPrice}
              onChange={handleFieldChange('overloadPrice')}
              fullWidth
              inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
            />
          </Grid>
        </Grid>
        {result && <CalculationResultDisplay result={result} />}
        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ mr: 2 }}
            disabled={result ? !result.isValid : true}
          >
            Enregistrer
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Annuler
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DosCarreColleModal;
