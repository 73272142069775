import React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../../shared/assets/logo.svg';
import HomeIcon from '@mui/icons-material/Home';
import { logout } from '../../../redux/actions/authActions';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import CustomModal from '../modal/CustomModal';
import AddCustomerForm from '../../Customer/AddCustomerForm';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import { Badge, Box, Popover } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsComponent from '../NotificationsComponent';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AddQuoteForm from '../../Quote/AddQuoteForm';
import { fetchNotifications } from '../../../redux/actions/notificationsActions';

const MenuDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const userId = useSelector((state: RootState) => state.auth.user?.id);

  const [anchorElNotifications, setAnchorElNotifications] = React.useState<null | HTMLElement>(null);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);

  const [openCreateClientModal, setOpenCreateClientModal] = useState(false);
  const [openCreateQuoteModal, setOpenCreateQuoteModal] = useState(false);

  const handleOpenNotificationsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotificationsMenu = () => {
    setAnchorElNotifications(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  React.useEffect(() => {
    dispatch(fetchNotifications(userId || 0));
  }, [dispatch, userId]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
    >
        <Box sx={{ justifyContent: 'center', backgroundColor: '#F5F6FA', borderBottom: '1px solid #fff', color: 'black' }} >
            <Toolbar sx={{height:50}}>
                <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={logo} alt="AppStudio" title="AppStudio" style={{ height: 40 }} />
                </Link>
            </Toolbar>
            <Divider />
            <Toolbar sx={{ justifyContent: 'center', mb: 1 }}>
              <Typography variant="body2" align="center" sx={{ fontWeight: 'bold', color: 'black' }}>
                {user?.email?.toUpperCase()}
              </Typography>
            </Toolbar>
        </Box>

      <Divider />

      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/">
            <ListItemIcon>
              <HomeIcon style={{ color: '#e5e7eb' }} />
            </ListItemIcon>
            <ListItemText primary="Tableau de bord" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/quote">
            <ListItemIcon>
              <AssignmentIcon style={{ color: '#e5e7eb' }} />
            </ListItemIcon>
            <ListItemText primary="Devis" />
          </ListItemButton>
          <IconButton onClick={() => setOpenCreateQuoteModal(true)} edge="end" aria-label="add" sx={{ marginRight: '10px' }}>
            <AddIcon style={{ color: '#e5e7eb' }} />
          </IconButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/customers">
            <ListItemIcon>
              <PeopleIcon style={{ color: '#e5e7eb' }} />
            </ListItemIcon>
            <ListItemText primary="Clients" />
          </ListItemButton>
          <IconButton onClick={() => setOpenCreateClientModal(true)} edge="end" aria-label="add" sx={{ marginRight: '10px' }}>
            <AddIcon style={{ color: '#e5e7eb' }} />
          </IconButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/services">
            <ListItemIcon>
              <BuildIcon style={{ color: '#e5e7eb' }} />
            </ListItemIcon>
            <ListItemText primary="Services" />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider sx={{ bgcolor: "secondary.light" }} />

      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/parameters">
            <ListItemIcon>
              <SettingsIcon style={{ color: '#e5e7eb' }} />
            </ListItemIcon>
            <ListItemText primary="Paramètres" />
          </ListItemButton>
        </ListItem>
      </List>

      <CustomModal
        open={openCreateClientModal}
        title="Nouveau client"
        onClose={() => {
          setOpenCreateClientModal(false);
        }}
      >
        <AddCustomerForm
          onClose={() => {
            setOpenCreateClientModal(false);
          }}
        />
      </CustomModal>

      <CustomModal
        open={openCreateQuoteModal}
        title="Nouveau devis"
        onClose={() => {
          setOpenCreateQuoteModal(false);
        }}
      >
        <AddQuoteForm
            onClose={() => {
                setOpenCreateQuoteModal(false);
            }}
        />
      </CustomModal>


      <Toolbar sx={{ justifyContent: 'center', marginTop: 'auto' }}>
        <Box>
          <IconButton onClick={handleOpenNotificationsMenu}>
            <Badge color="primary" badgeContent={notifications.length}>
              <NotificationsIcon style={{ color: '#e5e7eb' }} />
            </Badge>
          </IconButton>
          <Popover
            anchorEl={anchorElNotifications}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElNotifications)}
            onClose={handleCloseNotificationsMenu}
          >
            <Box sx={{ p: 2, minWidth: 250 }}>
              <NotificationsComponent />
            </Box>
          </Popover>
        </Box>
        <IconButton aria-label="Informations" component={Link} to="/details">
          <HelpCenterIcon style={{ color: '#e5e7eb' }} />
        </IconButton>
        <IconButton aria-label="A propos" component={Link} to="/aboutus">
          <InfoIcon style={{ color: '#e5e7eb' }} />
        </IconButton>
        <IconButton aria-label="Details" onClick={handleLogout}>
          <LogoutIcon style={{ color: '#e5e7eb' }} />
        </IconButton>
      </Toolbar>

    </Drawer>
  );
};

export default MenuDrawer;
