import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/api';
import { selectSelectedQuote, fetchQuotesRequest, fetchQuotesSuccess, fetchQuotesFailure, 
    addQuotesRequest, addQuotesSuccess, addQuotesFailure,
    fetchStatuesQuotesRequest, fetchStatuesQuotesSuccess, fetchStatuesQuotesFailure,
    updateStatusQuoteRequest, updateStatusQuoteSuccess, updateStatusQuoteFailure,
    addRowQuoteRequest, addRowQuoteSuccess, addRowQuoteFailure,
    deleteQuoteRequest, deleteQuoteSuccess, deleteQuoteFailure,
    duplicateQuoteRequest, duplicateQuoteSuccess, duplicateQuoteFailure,
    updateQuoteRequest, updateQuoteSuccess, updateQuoteFailure,
    deleteRowQuoteRequest, deleteRowQuoteSuccess, deleteRowQuoteFailure,
    updateQuoteRowRequest, updateQuoteRowSuccess, updateQuoteRowFailure,
    printQuoteRequest, printQuoteSuccess, printQuoteFailure
} from '../reducers/quoteReducer';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';
import { QuoteAddType, QuoteRowType, QuoteStatusType, QuoteType } from '../types/quotes';
import { ServiceParametersType } from '../types/services/serviceParameters';

export const selectQuote = createAsyncThunk(
  'quote/selectQuote',
  async (quote: QuoteType, { dispatch }) => {
    dispatch(selectSelectedQuote(quote));
  }
);

export const addQuote = createAsyncThunk(
  'quote/addQuote',
  async (quotes: QuoteAddType[], { dispatch }) => {
      try {
          dispatch(addQuotesRequest());
          const responses = await axiosInstance.post('/v1/quote', quotes);
          dispatch(addQuotesSuccess(responses.data));
      } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message;
          const errorCode = error.response?.status;
          dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
          dispatch(addQuotesFailure(errorMessage));
      }
  }
);


export const updateQuote = createAsyncThunk(
  'quote/updateQuote',
  async (quote: QuoteType, { dispatch }) => {
      try {
          dispatch(updateQuoteRequest());
          const responses = await axiosInstance.put('/v1/quote', quote);
          dispatch(updateQuoteSuccess(responses.data));
          dispatch(setAlertWithDuration('Mise a jour effectuée', 'success', '200'));
      } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message;
          const errorCode = error.response?.status;
          dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
          dispatch(updateQuoteFailure(errorMessage));
      }
  }
);

export const printQuote = createAsyncThunk(
  'quote/printQuote',
  async ({quoteId, quoteReference}:{ quoteId:number, quoteReference:string }, { dispatch }) => {
    try {
      dispatch(printQuoteRequest());
      const response = await axiosInstance.get(`/v1/documents/${quoteId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Devis_${quoteReference}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      dispatch(printQuoteSuccess());
      dispatch(setAlertWithDuration('Impression du devis réussie', 'success', '200'));
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(printQuoteFailure(errorMessage));
    }
  }
);

export const updateQuoteRow = createAsyncThunk(
  'quote/updateQuoteRow',
  async ({ quoteId, rowId, serviceParameters }:{ quoteId: number, rowId: number, serviceParameters: ServiceParametersType} , { dispatch }) => {
      try {
        dispatch(updateQuoteRowRequest());
        const responses = await axiosInstance.put(`/v1/quote/${quoteId}/row/${rowId}`, serviceParameters);
        dispatch(updateQuoteRowSuccess(responses.data));
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || error.message;
        const errorCode = error.response?.status;
        dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
        dispatch(updateQuoteRowFailure(errorMessage));
      }
  }
);


export const deleteRowQuote = createAsyncThunk(
  'quote/deleteRowQuote',
  async ({ quoteId, rowId }: { quoteId: number; rowId: number }, { dispatch }) => {
      try {
          dispatch(deleteRowQuoteRequest());
          await axiosInstance.delete(`/v1/quote/${quoteId}/row/${rowId}`);
          dispatch(deleteRowQuoteSuccess({ quoteId, rowId}));
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message;
          const errorCode = error.response?.status;
          dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
          dispatch(deleteRowQuoteFailure(errorMessage));
      }
  }
);


export const addRowQuote = createAsyncThunk(
  'quote/addRowQuote',
  async ({ quoteId, newRows }: { quoteId: number; newRows: QuoteRowType[] }, { dispatch }) => {
      try {
          dispatch(addRowQuoteRequest());
          const responses = await axiosInstance.post(`/v1/quote/${quoteId}/rows`, newRows);
          dispatch(addRowQuoteSuccess({ quoteId, rows: responses.data }));
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message;
          const errorCode = error.response?.status;
          dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
          dispatch(addRowQuoteFailure(errorMessage));
      }
  }
);
  

export const fetchQuotes = createAsyncThunk(
  'quote/fetchQuotes',
  async (_, { dispatch }) => {
      try {
        dispatch(fetchQuotesRequest());
        const response = await axiosInstance.get('/v1/quote');
        const data: QuoteType[] = response.data;
        dispatch(fetchQuotesSuccess(data));
      } catch (error :any) {
        const errorMessage = error.response?.data?.message || error.message;
        const errorCode = error.response?.status;
        dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
        dispatch(fetchQuotesFailure(errorMessage));
      }
  }
);

export const fetchStatuesQuotes = createAsyncThunk(
  'quote/fetchStatuesQuotes',
  async (_, { dispatch }) => {
      try {
        dispatch(fetchStatuesQuotesRequest());
        const response = await axiosInstance.get('/v1/quote/listStatus');
        const data: QuoteStatusType[] = response.data;
        dispatch(fetchStatuesQuotesSuccess(data));
      } catch (error :any) {
        const errorMessage = error.response?.data?.message || error.message;
        const errorCode = error.response?.status;
        dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
        dispatch(fetchStatuesQuotesFailure(errorMessage));
      }
  }
);


export const updateStatusQuote = createAsyncThunk(
  'quote/updateStatusQuote',
  async ({ quoteId, quoteStatusId }: { quoteId: number; quoteStatusId: number }, { dispatch }) => {
    try {
      console.log(quoteId, quoteStatusId);
      dispatch(updateStatusQuoteRequest());
      await axiosInstance.put(`/v1/quote/${quoteId}/status/${quoteStatusId}`);
      dispatch(updateStatusQuoteSuccess({ quoteId, quoteStatusId }));
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(updateStatusQuoteFailure(errorMessage));
    }
  }
);

export const deleteQuote = createAsyncThunk(
  'quote/deleteQuote',
  async ({ quoteId }: { quoteId: number }, { dispatch }) => {
    try {
      dispatch(deleteQuoteRequest());
      await axiosInstance.delete(`/v1/quote/${quoteId}`);
      dispatch(deleteQuoteSuccess(quoteId));
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(deleteQuoteFailure(errorMessage));
    }
  }
);

export const duplicateQuote = createAsyncThunk(
  'quote/duplicateQuote',
  async ({ quoteId, customerId }: { quoteId: number, customerId: number }, { dispatch }) => {
    try {
      dispatch(duplicateQuoteRequest());
      const response = await axiosInstance.get(`/v1/quote/${quoteId}/duplicate/${customerId}`);
      dispatch(duplicateQuoteSuccess(response.data));
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      const errorCode = error.response?.status;
      dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      dispatch(duplicateQuoteFailure(errorMessage));
    }
  }
);
