import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { QuoteRowType, QuoteStatusType, QuoteType } from '../types/quotes';

interface QuoteState {
  quotes: QuoteType[];
  selectQuote: QuoteType | null;
  loading: boolean;
  error: string | null;
  statues: QuoteStatusType[];
}

const initialState: QuoteState = {
  quotes: [],
  selectQuote: null,
  loading: false,
  error: null,
  statues: []
};

const quotesSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    selectSelectedQuote: (state, action: PayloadAction<QuoteType>) => {
      state.selectQuote = action.payload;
    },
    fetchQuotesRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchQuotesSuccess: (state, action: PayloadAction<QuoteType[]>) => {
      state.loading = false;
      state.quotes = action.payload;
    },
    fetchQuotesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addQuotesRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    addQuotesSuccess: (state, action: PayloadAction<QuoteType[]>) => {
      state.loading = false;
      state.quotes.push(...action.payload);
      state.selectQuote = state.quotes[state.quotes.length - 1];
    },
    addQuotesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchStatuesQuotesRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchStatuesQuotesSuccess: (state, action: PayloadAction<QuoteStatusType[]>) => {
      state.loading = false;
      state.statues = action.payload;
    },
    fetchStatuesQuotesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateStatusQuoteRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateStatusQuoteSuccess: (state, action: PayloadAction<{ quoteId: number, quoteStatusId: number }>) => {
      state.loading = false;
      const quoteIndex = state.quotes.findIndex(quote => quote.id === action.payload.quoteId);
      if (quoteIndex !== -1) {
        const status = state.statues.find(status => status.id === action.payload.quoteStatusId);
        if (status) {
          state.quotes[quoteIndex].status = status;
          if(state.selectQuote !== null && state.quotes[quoteIndex].id === state.selectQuote.id) {
            state.selectQuote.status = status;
          }
        }
      }
    },
    updateStatusQuoteFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addRowQuoteRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    addRowQuoteSuccess: (state, action: PayloadAction<{ quoteId: number, rows: QuoteRowType[]}>) => {
      state.loading = false;
      const { quoteId, rows } = action.payload;
      
      state.quotes.forEach((quote) => {
        if (quote.id === quoteId) {
          quote.rows = [...quote.rows || [], ...rows];
        }
      });

      if (quoteId === state.selectQuote?.id) {
        state.selectQuote.rows = [...state.selectQuote.rows || [], ...rows];
      }

    },
    addRowQuoteFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteQuoteRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteQuoteSuccess: (state, action: PayloadAction<number>) => {
      state.loading = false;
      const quoteIdToDelete = action.payload;
      state.quotes = state.quotes.filter(quote => quote.id !== quoteIdToDelete);
      if (state.selectQuote?.id === quoteIdToDelete) {
        state.selectQuote = null;
      }
    },
    deleteQuoteFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    duplicateQuoteRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    duplicateQuoteSuccess: (state, action: PayloadAction<QuoteType>) => {
      state.loading = false;
      state.quotes.push(action.payload);
      state.selectQuote = action.payload;
    },
    duplicateQuoteFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateQuoteRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateQuoteSuccess: (state, action: PayloadAction<QuoteType[]>) => {
      state.loading = false;
      action.payload.forEach(updatedQuote => {
        const quoteIndex = state.quotes.findIndex(quote => quote.id === updatedQuote.id);
        if (quoteIndex !== -1) {
          state.quotes[quoteIndex] = updatedQuote;
          if(state.quotes[quoteIndex].id === state.selectQuote?.id) {
            state.selectQuote = updatedQuote;
          }
        }
      });
    },
    
    updateQuoteFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteRowQuoteRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteRowQuoteSuccess: (state, action: PayloadAction<{ quoteId: number, rowId: number }>) => {
      state.loading = false;
      const { quoteId, rowId } = action.payload;
    
      state.quotes.forEach((quote) => {
        if (quote.id === quoteId && quote.rows) {
          // Filtrer les lignes pour supprimer celle avec rowId
          quote.rows = quote.rows.filter(row => row.id !== rowId);
          // Recalculer le montant total du devis
          quote.totalAmount = quote.rows.reduce((total, row) => total + (row.price || 0), 0);
          quote.totalAmount = Math.ceil(quote.totalAmount / 50) * 50;
        }
      });
    
      if (quoteId === state.selectQuote?.id && state.selectQuote.rows) {
        // Filtrer les lignes pour supprimer celle avec rowId
        state.selectQuote.rows = state.selectQuote.rows.filter(row => row.id !== rowId);
        console.log(state.selectQuote.totalAmount)
        // Recalculer le montant total du devis
        state.selectQuote.totalAmount = state.selectQuote.rows.reduce((total, row) => total + (row.price || 0), 0);
        state.selectQuote.totalAmount = Math.ceil(state.selectQuote.totalAmount / 50) * 50;

      }
    },
    
    deleteRowQuoteFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateQuoteRowRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateQuoteRowSuccess: (state, action: PayloadAction<QuoteType>) => {
      state.loading = false;
      const updatedQuote = action.payload;
      const quoteIndex = state.quotes.findIndex(quote => quote.id === updatedQuote.id);
      if (quoteIndex !== -1) {
        state.quotes[quoteIndex] = updatedQuote;
        if(state.quotes[quoteIndex].id === state.selectQuote?.id) {
          state.selectQuote = updatedQuote;
        }
      }
    },
    updateQuoteRowFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    printQuoteRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    printQuoteSuccess: (state) => {
      state.loading = false;
    },
    printQuoteFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  }
});

export const {
  selectSelectedQuote,
  fetchQuotesRequest, fetchQuotesSuccess, fetchQuotesFailure,
  addQuotesRequest, addQuotesSuccess, addQuotesFailure,
  fetchStatuesQuotesRequest, fetchStatuesQuotesSuccess, fetchStatuesQuotesFailure,
  updateStatusQuoteRequest, updateStatusQuoteSuccess, updateStatusQuoteFailure,
  addRowQuoteRequest, addRowQuoteSuccess, addRowQuoteFailure,
  deleteQuoteRequest, deleteQuoteSuccess, deleteQuoteFailure,
  duplicateQuoteRequest, duplicateQuoteSuccess, duplicateQuoteFailure,
  updateQuoteRequest, updateQuoteSuccess, updateQuoteFailure,
  deleteRowQuoteRequest, deleteRowQuoteSuccess, deleteRowQuoteFailure,
  updateQuoteRowRequest, updateQuoteRowSuccess, updateQuoteRowFailure,
  printQuoteRequest, printQuoteSuccess, printQuoteFailure
} = quotesSlice.actions;

export const selectQuotesLoading = (state: RootState) => state.quotes.loading;

export default quotesSlice.reducer;
