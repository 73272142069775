import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/api';
import { LineChartData, PieChartData } from '../types/charts/pieChart';
import { QuoteResumeType } from '../types/quotes';

export const fetchPieChart = createAsyncThunk(
    'charts/fetchPieChart',
    async () => {
        const response = await axiosInstance.get<PieChartData[]>('/v1/quote/piechart');
        return response.data;
    }
);


export const fetchLineChart = createAsyncThunk(
    'charts/fetchLineChart',
    async () => {
        const response = await axiosInstance.get<LineChartData[]>('/v1/quote/lineChart');
        return response.data;
    }
);


export const fetchNextQuoteToExpire = createAsyncThunk(
    'quote/fetchNextQuoteToExpire',
    async (nbQuote: number) => {
        const response = await axiosInstance.get<QuoteResumeType[]>(`/v1/quote/next-quotes-due-to-expire?nbQuote=${nbQuote}`);
        return response.data;
    }
);
  
  export const fetchNextToPayments = createAsyncThunk(
    'quote/fetchNextToPayments',
    async (nbPayment: number) => {
        const response = await axiosInstance.get<QuoteResumeType[]>(`/v1/quote/next-payments?nbPayment=${nbPayment}`);
        return response.data;
    }
);