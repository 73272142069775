import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteNotification, fetchNotifications } from '../actions/notificationsActions';
import { NotificationType } from '../types/notifications';

interface NotificationsState {
    notifications: NotificationType[];
    loading: boolean;
    error: string | null;
}

const initialState: NotificationsState = {
    notifications: [],
    loading: false,
    error: null
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: builder => {
        // Action pour charger les notifications
        builder.addCase(fetchNotifications.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchNotifications.fulfilled, (state, action: PayloadAction<NotificationType[]>) => {
            state.loading = false;
            state.notifications = action.payload;
        });
        builder.addCase(fetchNotifications.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message ?? 'Une erreur s\'est produite lors de la récupération des notifications.';
        });

        // Action pour supprimer une notification
        builder.addCase(deleteNotification.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteNotification.fulfilled, (state, action: PayloadAction<number>) => {
            state.loading = false;
            state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
        });
        builder.addCase(deleteNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message ?? 'Une erreur s\'est produite lors de la suppression de la notification.';
        });
    }
});

export default notificationsSlice.reducer;
