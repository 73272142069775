import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";
import { LogsData } from "../types/types";

export const fetchLogs = createAsyncThunk(
    'logs/fetchLogs',
    async () => {
        const response = await axiosInstance.get<LogsData[]>('/v1/logs');
        return response.data;
    }
);
