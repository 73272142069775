import React, { FC, useEffect, useState } from 'react';
import { TextField, Grid, Button, InputAdornment, Typography, FormControl, InputLabel, MenuItem, Select, Box, Checkbox, FormControlLabel } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { addQuote } from '../../redux/actions/quotesActions';
import { QuoteStatusType } from '../../redux/types/quotes';
import { useSelector } from 'react-redux';
import { fetchCustomers } from '../../redux/actions/custActions';
import { CustomerType } from '../../redux/types/customers';
import { ServiceType } from '../../redux/types/services';
import { fetchServices } from '../../redux/actions/servicesActions';
import { useNavigate } from 'react-router-dom';
import AddServiceForm from '../Services/AddServiceForm';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';

interface AddQuoteFormProps {
  onClose: () => void;
}


const AddQuoteForm: FC<AddQuoteFormProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const customers = useSelector((state: RootState) => state.cust.customers as CustomerType[] );
  const services = useSelector((state: RootState) => state.services.services as ServiceType[]);

  useEffect(() => {
    dispatch(fetchCustomers());
    dispatch(fetchServices());
  }, [dispatch]);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status] = useState<QuoteStatusType>({
    id: 1,
    name: 'Envoyé',
    description: '',
  });
  const [customer, setCustomer] = useState<CustomerType>();
  const [customerReference, setCustomerReference] = useState('');
  const [expirationDate, setExpirationDate] = useState<Date>(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000));
  const [selectedServices, setSelectedServices] = useState<ServiceType[]>([]);
  const [showPriceService, setShowPriceService] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const selectedServiceIds = selectedServices.map(service => service.id);

      if(customer !== undefined ) {
        await dispatch(
          addQuote([{
            title,
            description,
            statusId: status.id,
            customerId: customer.id,
            customerReference,
            expirationDate,
            selectedServiceIds,
            showPriceService
          }])
        );
        navigate('/quote/details');
        onClose();
      }
      else {
        dispatch(setAlertWithDuration('Veuillez choisir un client', 'error', '401'));
      }
    } catch (error) {
      console.error('Une erreur s\'est produite lors de la création du devis :', error);
    }
  };

  const handleCardClick = (service: ServiceType) => {
    handleServiceToggle(service);
  };

  const handleServiceToggle = (service: ServiceType) => {
    if (selectedServices.find(selectedService => selectedService.id === service.id)) {
      setSelectedServices(selectedServices.filter(selectedService => selectedService.id !== service.id));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
          <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2} >
            {/* Section Général - Colonnes 1 & 2 */}
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
                Général
              </Typography>
              <Box p={2}>
                <TextField
                  label="Titre"
                  fullWidth
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <FormControl fullWidth variant="outlined" style={{ marginTop: '16px' }}>
                  <InputLabel id="customer-label">Client</InputLabel>
                  <Select
                    labelId="customer-label"
                    value={customer ? customer.id : ''} 
                    onChange={(e) => setCustomer(customers.find(customer => customer.id === e.target.value))}
                    label="Client"
                  >
                  {customers.map((customer) => (
                    <MenuItem key={customer.id} value={customer.id}>
                      {customer.name}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                <TextField
                  label="Description"
                  fullWidth
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={4}
                  style={{ marginTop: '16px' }}
                  inputProps={{ maxLength: 255 }}
                />
                <TextField
                  label="Référence client"
                  fullWidth
                  variant="outlined"
                  value={customerReference}
                  onChange={(e) => setCustomerReference(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessCenterIcon />
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginTop: '16px' }}
                />
                <TextField
                  label="Date d'expiration"
                  type="date"
                  fullWidth
                  variant="outlined"
                  value={expirationDate ? expirationDate.toISOString().split('T')[0] : ''}
                  onChange={(e) => setExpirationDate(new Date(e.target.value))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginTop: '16px' }}
                />
                <FormControlLabel label="Afficher le détails des prix" sx={{ mb: 2 }}
                  control={
                  <Checkbox
                    checked={showPriceService}
                    onChange={(e) => setShowPriceService(e.target.checked)}
                  />}
                />
              </Box>
            </Grid>
            {/* Section Choix des prestations */}
            <AddServiceForm
              services={services}
              selectedServices={selectedServices}
              handleCardClick={handleCardClick}
              handleServiceToggle={handleServiceToggle}
            />
            <Grid item xs={12} md={12} mb={2}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Créer le devis
              </Button>
            </Grid>
          </Grid>
        </form>
  );
};

export default AddQuoteForm;
