import React, { FC, ReactNode } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import theme from '../../../shared/theme/theme';

interface CustomModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
}

const CustomModal: FC<CustomModalProps> = ({ open, title, onClose, children }) => {
  const modalTheme = createTheme(theme);

  return (
    <ThemeProvider theme={modalTheme}>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          bgcolor: 'background.paper',
          p: 4,
          width: 600,
          maxHeight: '80vh'
        }}>
          <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
            {title}
          </Typography>
          {children}
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default CustomModal;
