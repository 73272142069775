import React, { FC, useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Grid, TextField, IconButton } from '@mui/material';
import { AutreParametersType } from '../../../../redux/types/services/serviceParameters';
import { updateQuoteRow } from '../../../../redux/actions/quotesActions';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { calculationRow } from '../../../../redux/actions/calculActions';
import { useSelector } from 'react-redux';
import CalculationResultDisplay from '../CalculationResultDisplay';
import { clearCalculationResult } from '../../../../redux/reducers/calculReducer';
import { QuoteRowType } from '../../../../redux/types/quotes';
import PopoverDetails from '../../PopoverDetails';
import CancelIcon from '@mui/icons-material/Cancel';

interface AutreProps {
  open: boolean;
  row: QuoteRowType;
  quoteId: number;
  handleClose: () => void;
}

const AutreModal: FC<AutreProps> = ({ open, row, quoteId, handleClose }) => {
  const dispatch = useAppDispatch();

  const result = useSelector((state: RootState) => state.calcul.result);

  const [formValues, setFormValues] = useState<AutreParametersType>({
    type: 'autre',
    description: row.description || '',
    price: row.price || 0,
    overloadPrice: row.overloadPrice || 0,
    rowId: row.id || 0,
    serviceId: row.serviceId,
    quoteId: quoteId,
  });

  useEffect(() => {
    if (open) {
      dispatch(clearCalculationResult());
    }
  }, [open, dispatch]);


  useEffect(() => {
    if (row.id !== null && quoteId !== null) {
      dispatch(calculationRow({
        quoteId,
        rowId: row.id || 0,
        serviceParameters:formValues
      }))
    }
  }, [formValues, row.id, quoteId, dispatch]);

  const handleChange = (field: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(field, event.target.value);
  };

  const handleSave = () => {
    if (row.id !== null && quoteId !== null) {
      formValues.price = result?.price || 0;
      dispatch(updateQuoteRow({
        quoteId,
        rowId: row.id || 0,
        serviceParameters: formValues
      }));
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: 4,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2
      }}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Autre
          </Typography>
        </Box>
        <Box sx={{ position: 'absolute', top: 16, right: 16, display: 'flex', alignItems: 'center' }}>
          <PopoverDetails details={result?.details || []} />
          <IconButton aria-label="cancel" onClick={handleClose}>
            <CancelIcon sx={{ color: '#d32f2f' }} />
          </IconButton>
        </Box>
        <Box sx={{ padding: '16px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Description"
                value={formValues.description}
                onChange={handleFieldChange('description')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Montant en €"
                value={formValues.overloadPrice}
                onChange={handleFieldChange('overloadPrice')}
                fullWidth
                inputProps={{
                  pattern: '[0-9]*\\.?[0-9]+',
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {result && <CalculationResultDisplay result={result} />}
        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ mr: 2 }}
            disabled={result ? !result.isValid : true}
          >
            Enregistrer
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Annuler
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AutreModal;
