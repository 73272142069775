import React, { FC, useState } from 'react';
import { TextField, Grid, Button, InputAdornment, Typography } from '@mui/material';
import { AdresseType } from '../../redux/types/customers';
import AddressForm from './AdressForm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useAppDispatch } from '../../redux/store/store';
import { addCustomer } from '../../redux/actions/custActions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface AddClientFormProps {
  onClose: () => void;
}

const schema = yup.object().shape({
  name: yup.string().required('Le nom est obligatoire'),
  email: yup.string().email('Veuillez entrer une adresse email valide').required('L\'email est obligatoire'),
  phoneNumber: yup.string()
    .required('Le numéro de téléphone est obligatoire')
    .matches(/^[0-9]{10}$/, 'Le numéro de téléphone doit comporter 10 chiffres'),
  siret: yup.string()
    .required('Le SIRET est obligatoire')
    .matches(/^[0-9]{14}$/, 'Le SIRET doit comporter 14 chiffres'),
});


const AddCustomerForm: FC<AddClientFormProps> = ({ onClose }) => {

  const dispatch = useAppDispatch();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const [address, setAddress] = useState<AdresseType>({
    id: 0,
    row1: '',
    row2: '',
    row3: '',
    city: '',
    zipCode: '',
    country: '',
  });

  const onSubmit = async (data: any) => {
    await dispatch(addCustomer([{ ...data, address, customerServices: [] }]));
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <TextField
            label="Nom*"
            fullWidth
            variant="outlined"
            {...register('name')}
            error={!!errors.name}
            helperText={errors.name?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email*"
            fullWidth
            variant="outlined"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Téléphone*"
            fullWidth
            variant="outlined"
            {...register('phoneNumber')}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Siret*"
            fullWidth
            variant="outlined"
            {...register('siret')}
            error={!!errors.siret}
            helperText={errors.siret?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessCenterIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
            <Typography>Adresse</Typography>
            <AddressForm address={address} onChange={setAddress} />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Créer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCustomerForm;
