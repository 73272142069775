import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersState, UserType } from '../types/users';
import { RootState } from '../store/store';
import { updateUser, updateUserStatus } from '../actions/usersActions';

const initialState: UsersState = {
  users: [],
  loading: false,
  error: null,
};

const UsersSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    fetchUsersRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchUsersSuccess: (state, action: PayloadAction<UserType[]>) => {
      state.loading = false;
      state.users = action.payload;
    },
    fetchUsersFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addUserRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    addUserSuccess: (state, action: PayloadAction<UserType>) => {
      state.loading = false;
      state.error = null;
      state.users.push(action.payload);
    },
    addUserFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    changePasswordRequest(state) {
      state.loading = false;
      state.error = null;
    },
    changePasswordSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    changePasswordFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateUser.fulfilled, (state, action: PayloadAction<UserType>) => {
      state.loading = false;
      state.error = null;
      const index = state.users.findIndex(user => user.id === action.payload.id);
      if (index !== -1) {
        state.users[index] = action.payload;
      }
    });
    builder.addCase(updateUser.rejected, (state, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload || 'An unknown error occurred';
    });
    builder.addCase(updateUserStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateUserStatus.fulfilled, (state, action: PayloadAction<UserType>) => {
      state.loading = false;
      state.error = null;
      const index = state.users.findIndex(user => user.id === action.payload.id);
      if (index !== -1) {
        state.users[index] = action.payload;
      }
    });
    builder.addCase(updateUserStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string || 'An unknown error occurred';
    });
  }
});

export const { fetchUsersRequest, fetchUsersSuccess, fetchUsersFailure,
  addUserRequest, addUserSuccess, addUserFailure,
  changePasswordRequest, changePasswordSuccess, changePasswordFailure } = UsersSlice.actions;

export const selectUsersLoading = (state: RootState) => state.users.loading;

export default UsersSlice.reducer;
