import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/api';
import { setAlertWithDuration } from '../reducers/alertReducer';
import { ServiceParametersType } from '../types/services/serviceParameters';
import { calculationRowRequest, calculationRowSuccess,
    calculationRequest, calculationSuccess, calculationFailure, calculationRowFailure
} from '../reducers/calculReducer';

export const calculationRow = createAsyncThunk(
    'calcul/calculationRow',
    async ({ quoteId, rowId, serviceParameters }:{ quoteId: number, rowId: number, serviceParameters: ServiceParametersType} , { dispatch }) => {
      try {
        dispatch(calculationRowRequest());
        const response = await axiosInstance.post(`/v1/calcul/${quoteId}/rows/${rowId}/calculation`, serviceParameters);
        dispatch(calculationRowSuccess(response.data));
      } catch (error: any) {
        dispatch(calculationRowFailure());
      }
    }
);

export const calculation = createAsyncThunk(
    'calcul/calculation',
    async ({ quoteId }:{ quoteId: number, rowId: number, serviceParameters: ServiceParametersType} , { dispatch }) => {
      try {
        dispatch(calculationRequest());
        const response = await axiosInstance.get(`/v1/calcul/${quoteId}`);
        dispatch(calculationSuccess(response.data));
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || error.message;
        const errorCode = error.response?.status;
        dispatch(calculationFailure(error));
        dispatch(setAlertWithDuration(errorMessage, 'error', errorCode));
      }
    }
);