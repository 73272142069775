import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { QuoteStatusType } from '../../redux/types/quotes';
import { Box } from '@mui/material';

type StepperProps = {
  statuses: QuoteStatusType[];
  activeStep: QuoteStatusType;
};

const QuoteStepper: React.FC<StepperProps> = ({ statuses, activeStep }) => {
  const [localActiveStep, setLocalActiveStep] = React.useState(0);

  // Mettre à jour l'étape active locale lorsque la prop activeStep change
  React.useEffect(() => {
    const index = statuses.findIndex(status => status.id === activeStep.id);
    if (index !== -1) {
      setLocalActiveStep(index);
    }
  }, [statuses, activeStep]);

  return (
    <Box>
      <Stepper activeStep={localActiveStep} sx={{ mb: 3 }}>
        {statuses.map((status, index) => (
          <Step key={status.id}>
            <StepLabel>{status.name}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default QuoteStepper;
