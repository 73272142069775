import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store/store';
import { fetchCustomers } from '../../redux/actions/custActions';
import { RootState } from '../../redux/store/store';
import { Typography, Box } from '@mui/material';
import CustomModal from '../common/modal/CustomModal';
import AddCustomerForm from '../Customer/AddCustomerForm';
import AlertComponent from '../common/Alert';
import { formatAddress } from '../../utils/utils';
import LoadingIndicator from '../common/LoadingIndicator';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import { selectCustomer } from '../../redux/actions/custActions';
import MenuDrawer from '../common/Drawer/MenuDrawer';

const CustomersPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clients = useSelector((state: RootState) => state.cust.customers);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const [openCreateClientModal, setOpenCreateClientModal] = useState(false);

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      label: "ID",
      options: {
        display: 'false',
        filter: false,
      }
    },
    {
      name: "name",
      label: "Nom"
    },
    {
      name: "email",
      label: "Email"
    },
    {
      name: "siret",
      label: "Siret"
    },
    {
      name: "phoneNumber",
      label: "Téléphone"
    },
    {
      name: "address",
      label: "Adresse"
    }
  ];

  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: 'none',
    elevation: 0,
    sortOrder: {
      name: 'name',
      direction: 'asc'
    },
    onRowClick: (rowData, rowMeta) => {
      const customerId = parseInt(rowData[0], 10);
      const customer = clients.find(client => client.id === customerId);
      if (customer) {
        dispatch(selectCustomer(customer));
        navigate(`/customer/details`, { replace: true });
      }
    },
    textLabels: {
      body: {
        noMatch: "Aucun client disponible",
      },
      pagination: {
        rowsPerPage: "Lignes par page:",
      },
      filter: {
        all: "Tous",
        title: "Mes filtres",
        reset: "Réinitialiser",          
      },
      toolbar: {
        viewColumns: "Affichage",
        filterTable: "Filtrer",
        search: "Rechercher"
      },
      viewColumns: {
        title: ""
      }
    }
  };

  const data = clients.map(client => ({
    ...client,
    address: formatAddress(client.address)
  }));

  return (
    <Box sx={{ display: 'flex' }}> {/* Ajouter un conteneur flexible */}
      <MenuDrawer />
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <AlertComponent />
        <LoadingIndicator />
        <Box textAlign="center" my={4}>
          <Typography variant="h2" gutterBottom>
            Liste des clients
          </Typography>
        </Box>
        <Box sx={{ margin: '0 auto'}}>
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        </Box>
        <CustomModal
          open={openCreateClientModal}
          title="Créer un client"
          onClose={() => {
            setOpenCreateClientModal(false);
          }}
        >
          <AddCustomerForm
            onClose={() => {
              setOpenCreateClientModal(false);
            }}
          />
        </CustomModal>
      </Box>
    </Box>
  );
};

export default CustomersPage;
