import React, { FC, useState } from 'react';
import { Popover, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface PopoverDetailsProps {
  details: string[];
}

const PopoverDetails: FC<PopoverDetailsProps> = ({ details }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-details' : undefined;

  return (
    <div>
      <IconButton aria-label="Details" onClick={handleClick}>
        <InfoIcon sx={{ color: 'gray' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {details.map((detail, index) => (
            <ListItem key={index}>
              <ListItemText>
                <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>{/* Adjust font size here */}
                  {detail}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default PopoverDetails;
